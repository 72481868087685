import { useState } from "react";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

const EditBox = ({
  editing,
  state,
  title,
  text,
  helperText,
  icon,
  button,
  disabled,
  buttonText,
  children,
  onSave,
  saveDisabled,
  divider = true,
}: {
  editing: boolean;
  state: boolean;
  title: string;
  text?: any;
  helperText?: any;
  icon: any;
  disabled?: boolean;
  button?: any;
  buttonText?: string;
  divider?: boolean;
  onSave?: () => void;
  saveDisabled?: boolean;
  children?: React.ReactNode;
}) => {
  const [edit, setEdit] = useState(editing);

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack direction="row" alignItems="initial" spacing={2} flex={1}>
          <Box sx={{ mt: "5px" }}>{icon}</Box>
          <Box flex={1}>
            <Typography variant="h6" fontWeight={state ? 500 : 400}>
              {title}
            </Typography>
            {text && !edit && text}
            {helperText && edit && helperText}
            {edit && (
              <Box marginTop={2}>
                {children}
                {onSave && (
                  <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button
                      disabled={saveDisabled}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        onSave();
                        setEdit(false);
                      }}
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        </Stack>
        {button}
        {buttonText && !edit && (
          <Button
            variant="outlined"
            size="small"
            disabled={disabled}
            onClick={() => {
              setEdit(true);
            }}
          >
            {buttonText}
          </Button>
        )}
      </Stack>
      {divider && <Divider sx={{ my: 2 }} />}
    </>
  );
};

EditBox.defaultProps = {
  editing: false,
  disabled: false,
  saveDisabled: false,
};

export default EditBox;
