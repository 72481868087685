import { fetchData } from "../fetchData";

export const navigateToOrganization = async (
  reference: number,
  navigate: any,
) => {
  try {
    const person = await fetchData(`/organizations?reference=${reference}`, {
      method: "GET",
    });

    if (person.count) {
      navigate(`/organizations/${person.rows[0]._id}`);
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
    return;
  }
};
