import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import EditBox from "../../../../components/EditBox";

import { useNps } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";
import AddNpsQuestion from "../modal/addQuestion";

const NpsEditQuestionsBox = () => {
  const snackbar = useSnackbar();
  const modal = useModal();
  const { data, save } = useNps();

  const handleRemoveQuestion = async (id: number) => {
    if (
      await save({
        questions: data.questions.filter(
          (question: any, index: number) => index != id,
        ),
      })
    ) {
      snackbar?.open("Questão excluída com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a exclusão.",
        "error",
      );
    }
  };

  return (
    <EditBox
      state={false}
      editing={true}
      title="Conteúdo"
      icon={
        <CheckCircleIcon
          color={data.questions.length > 0 ? "success" : "disabled"}
        />
      }
      button={
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            modal?.open(
              "Nova Questão",
              <AddNpsQuestion values={data} handleDataSave={save} />,
            );
          }}
        >
          Criar questão
        </Button>
      }
      text={
        data.questions?.length === 0
          ? "Formule uma pergunta para medir a satisfação e a lealdade dos clientes."
          : null
      }
    >
      {data.questions.length > 0 && (
        <Stack>
          {data.questions.map((question: string, index: number) => (
            <Box key={`question-${index}`}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="caption"
                  component="div"
                  flexGrow={1}
                  borderLeft={2}
                  borderColor="primary.main"
                  paddingLeft={2}
                >
                  {question}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleRemoveQuestion(index);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Divider sx={{ my: 1 }} />
            </Box>
          ))}
        </Stack>
      )}
    </EditBox>
  );
};

export default NpsEditQuestionsBox;
