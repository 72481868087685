import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { CloseSharp } from "@mui/icons-material";
import { useSnackbar } from "../../contexts/SnackbarContext";

interface SnackbarProps {
  anchorOrigin?: any;
}

const SimpleSnackbar: React.FC<SnackbarProps> = ({
  anchorOrigin,
}: SnackbarProps) => {
  const snackbar = useSnackbar();

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={snackbar?.close}
      >
        <CloseSharp fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={snackbar?.state.open}
      autoHideDuration={6000}
      onClose={snackbar?.close}
      action={action}
    >
      <Alert
        onClose={snackbar?.close}
        severity={snackbar?.state.severity}
        sx={{ width: "100%" }}
      >
        {snackbar?.state.message}
      </Alert>
    </Snackbar>
  );
};

SimpleSnackbar.defaultProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default SimpleSnackbar;
