import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Link,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useOrganization } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";

const OrganizationPersonsTab = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const { data, set } = useOrganization();
  const [progress, setProgress] = useState(true);
  const [error, setError] = useState(false);
  const [persons, setPersons] = useState<object[]>([]);

  const init = async () => {
    try {
      const result = await fetchData(
        `/users?is_admin=false&client=${data.reference}&sort=name&limit=100&skip=0`,
        {
          method: "GET",
        },
      );

      setPersons(result.rows);
    } catch (error) {
      setError(true);
    }

    setProgress(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data._id]);

  const handleAddLegalResponsible = async (_id: string) => {
    try {
      await fetchData(`/organizations/${data._id}/legalUser`, {
        method: "POST",
        body: JSON.stringify({
          _id,
        }),
      });

      set({ ...data, legal_responsible: _id });

      snackbar?.open("Responsável legal atualizado com sucesso!", "success");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível atualizar o responsável legal!",
        "error",
      );
    }
  };

  if (progress) {
    return <LinearProgress sx={{ m: 2 }} />;
  }

  return (
    <Box sx={{ p: 2 }}>
      {persons.length > 0 && (
        <TableContainer sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Contato</TableCell>
                <TableCell width={80}>Usuário</TableCell>
                <TableCell width={80}>Situação</TableCell>
                <TableCell align="center" width={80}>
                  <MoreVertIcon />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {persons.map((row: any) => (
                <TableRow
                  key={row._id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                    backgroundColor: !row.status
                      ? "rgba(255, 99, 71,.2)"
                      : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      onClick={() => {
                        navigate(`/persons/${row._id}`);
                      }}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {row.name}
                    </Link>
                    {row.status && data.legal_responsible === row._id && (
                      <Chip
                        size="small"
                        label="Responsável Legal"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.login}</TableCell>
                  <TableCell>{row.status ? "Ativo" : "Inativo"}</TableCell>
                  {auth?.user.permission_level === "manager" && (
                    <TableCell align="center">
                      {row.status && data.legal_responsible !== row._id && (
                        <Tooltip title="Marcar Responsável Legal">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              handleAddLegalResponsible(row._id);
                            }}
                          >
                            <AdminPanelSettingsIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default OrganizationPersonsTab;
