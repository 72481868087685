import { useEffect, useState } from "react";
import { MenuItem, Stack, TextField, Alert } from "@mui/material";
import { useSegment } from "..";

const triggers = [
  {
    label: "É",
    value: "is",
  },
  {
    label: "Não é",
    value: "is not",
  },
  {
    label: "Contém",
    value: "contains",
  },
  {
    label: "Não contém",
    value: "not contains",
  },
  {
    label: "É maior que",
    value: "greater than",
  },
  {
    label: "É menor que",
    value: "less than",
  },
];

const SegmentFilterDefaultCondition = ({
  target,
  index,
}: {
  target: string;
  index: number;
}) => {
  const segment = useSegment();
  const [state, setState] = useState({
    condition: segment.data.filters[index].condition || "is",
    value: segment.data.filters[index].value || "",
    error: false,
  });

  useEffect(() => {
    const newFilter = [...segment.data.filters];

    newFilter[index] = {
      ...newFilter[index],
      condition: state.condition,
      value: state.value,
      error: false,
    };

    segment.set({ ...segment.data, filters: newFilter });
  }, [state.value, state.condition]);

  useEffect(() => {
    setState({ ...state, error: segment.data.filters[index].error });
  }, [segment.data.filters[index].error]);

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        label="Condição"
        select
        value={state.condition}
        sx={{ flex: 1 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setState({ ...state, condition: e.target.value });
        }}
      >
        {triggers.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="valor"
        value={state.value}
        sx={{ flex: 1 }}
        error={state.error}
        helperText={
          target === "reference"
            ? "Você pode inserir mais de uma referência separando cada número por uma vírgula."
            : ""
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setState({ ...state, value: e.target.value });
        }}
      ></TextField>
      {state.error && (
        <Alert severity="error" sx={{ alignItems: "center" }}>
          Insira um valor de campo de mesclagem
        </Alert>
      )}
    </Stack>
  );
};

export default SegmentFilterDefaultCondition;
