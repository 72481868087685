import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

import { fetchData } from "../../fetchData";

const colorSwitch = (num: number) => {
  switch (num) {
    case 2:
      return "#FF624D";
    case 3:
      return "#FF8352";
    case 4:
      return "#FFAA4D";
    case 5:
      return "#FFD550";
    case 6:
      return "#E7E035";
    case 7:
      return "#DEF237";
    case 8:
      return "#AFEF41";
    case 9:
      return "#69D63E";
    case 10:
      return "#45E330";
    default:
      return "#FC495B";
  }
};

const NpsSurvey = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("userid");
  const score = params.get("q");
  const { id } = useParams();
  const [progress, setProgress] = useState(true);
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState<any>([]);
  const [comment, setComment] = useState<string>("");
  const [answered, setAnswered] = useState<any>(false);
  const [scoreData, setScoreData] = useState<any>({});
  const [error, setError] = useState(false);
  const [nps, setNps] = useState<any>({});
  const values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleNpsScoreSave = async (answers: any) => {
    try {
      let questions: any = [];

      const data: { survey: any; answers?: Array<object> } = {
        survey: id,
      };

      questions = [...answers];
      questions[current] = {
        ...questions[current],
        value: score ? Number(score) : null,
      };
      data.answers = questions;

      setAnswers(questions);

      const response = await fetchData(`/surveys/nps/score?userid=${userId}`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      setScoreData(response);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const handleNpsScoreUpdate = async () => {
    try {
      console.log({ ...scoreData, answers });
      const response = await fetchData(`/surveys/nps/score/${scoreData._id}`, {
        method: "PUT",
        body: JSON.stringify({ ...scoreData, answers }),
      });
      setScoreData(response);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const init = async () => {
    try {
      const check = await fetchData(`/surveys/nps/score/${id}/user/${userId}`, {
        method: "GET",
      });

      if (check._id) {
        setAnswered(true);
        setProgress(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const questions: any = [];

      const response = await fetchData(`/surveys/nps/${id}`, {
        method: "GET",
      });

      setNps(response);

      response.questions.map((question: string) => {
        questions.push({
          question,
          value: null,
          comment: null,
        });
      });

      handleNpsScoreSave(questions);
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (scoreData._id) handleNpsScoreUpdate();
  }, [answers]);

  const handleScoreSubmit = async (score: number) => {
    const updatedAnswers = [...answers];

    updatedAnswers[current] = { ...updatedAnswers[current], value: score };

    setAnswers(updatedAnswers);
  };

  const handleCommentSubmit = async () => {
    const updatedAnswers = [...answers];
    const next = current + 1;

    updatedAnswers[current] = { ...updatedAnswers[current], comment };

    setAnswers(updatedAnswers);
    setComment("");
    setCurrent(next);
  };

  if (progress) return <LinearProgress />;

  if (answered) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        spacing={2}
        flexWrap="wrap"
      >
        <Typography variant="h4" color="primary.main">
          Agradecemos sua participação!
        </Typography>
        <Typography variant="body1">
          Você já respondeu a esta pesquisa e nos ajudou a entender em como
          podemos melhorar nossos serviços para atender melhor às suas
          necessidades.
        </Typography>
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        spacing={2}
        flexWrap="wrap"
      >
        <Typography variant="h4" color="error">
          Ops!
        </Typography>
        <Typography variant="body1">
          Um erro inesperado ocorreu. Pedimos desculpas pelo transtorno. Tente
          novamente mais tarde.
        </Typography>
      </Stack>
    );
  }

  if (current > nps?.questions.length - 1) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        spacing={2}
        flexWrap="wrap"
      >
        <Typography variant="h4" color="primary.main">
          Agradecemos sua participação!
        </Typography>
        <Typography variant="body1">
          Sua opinião é muito valiosa para nós e nos ajuda a entender como
          podemos melhorar nossos serviços para atender melhor às suas
          necessidades.
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {nps?.questions && nps?.questions.length > 0 && (
        <Stack direction="column" spacing={3}>
          {answers[current]?.value === null && (
            <>
              <Typography variant="h5">{nps.questions[current]}</Typography>
              <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                flexWrap="wrap"
              >
                {values.map((num) => (
                  <Button
                    size="large"
                    key={`value-${num}`}
                    variant="contained"
                    sx={{
                      p: { xs: "3px", lg: "5px" },
                      minWidth: { xs: "30px", lg: "40px" },
                      backgroundColor: colorSwitch(num),
                    }}
                    onClick={() => {
                      handleScoreSubmit(num);
                    }}
                  >
                    {num}
                  </Button>
                ))}
              </Stack>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                spacing={1}
              >
                <Typography variant="caption">0 = De jeito nenhum</Typography>
                <Typography variant="caption">10 = Muito provável</Typography>
              </Stack>
            </>
          )}
          {answers[current]?.value !== null &&
            answers[current]?.comment === null && (
              <>
                <Typography variant="h5">
                  Nos fale um pouco mais sobre o motivo de você ter escolhido a
                  nota {answers[current]?.value}?
                </Typography>
                <TextField
                  label="Comentário"
                  autoFocus
                  multiline
                  value={comment}
                  rows={3}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      handleCommentSubmit();
                    }}
                  >
                    Prefiro não dizer
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleCommentSubmit();
                    }}
                  >
                    Enviar
                  </Button>
                </Stack>
              </>
            )}
        </Stack>
      )}
    </>
  );
};

export default NpsSurvey;
