import AuthProvider from "./providers/AuthProvider";
import ThemesProvider from "./providers/ThemeProvider";
import SnackbarProvider from "./providers/SnackbarProvider";
import ModalProvider from "./providers/ModalProvider";
import DialogProvider from "./providers/DialogProvider";
import Routes from "./routes/index";

export default function App() {
  return (
    <AuthProvider>
      <ThemesProvider>
        <SnackbarProvider>
          <DialogProvider>
            <ModalProvider>
              <Routes />
            </ModalProvider>
          </DialogProvider>
        </SnackbarProvider>
      </ThemesProvider>
    </AuthProvider>
  );
}
