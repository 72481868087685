import { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import {
  Alert,
  MenuItem,
  TextField,
  Divider,
  Grid,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";

import SelectChipField from "../../../components/SelectChipField";
import DatePickerField from "../../../components/DatePickerField";

import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import { profiles } from "../../../utils/profileHandler";
import { phoneLabels, contactLabels } from "../../../utils/labels";

import { profileSwitch } from "../../../utils/profileHandler";

import { fetchData } from "../../../fetchData";

import { useSnackbar } from "../../../contexts/SnackbarContext";
import { usePerson } from ".";

interface Props {
  setIsEditing: any;
}

type FormValues = {
  name: string;
  login: string;
  document: string;
  register: string;
  profile: Array<string>;
  birthdate: Date;
  phone: {
    value: string;
    label: string;
  }[];
  email: {
    value: string;
    label: string;
  }[];
  status: boolean;
  address: {
    zipcode: string;
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
  };
};

const Edit = (props: Props) => {
  const snackbar = useSnackbar();
  const { data: person, set } = usePerson();
  const [progress, setProgress] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: person.name,
      login: person.login,
      profile: person.profile,
      birthdate: person.birthdate,
      document: person.document,
      register: person.register,
      phone: person.phone,
      email: person.email,
      status: person.status.toString(),
      address: person.address,
    },
  });

  const {
    fields: phones,
    append: addPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "phone",
  });

  const {
    fields: emails,
    append: addEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const watchPhone = watch("phone");
  const watchEmail = watch("email");

  const controlledPhones = phones.map((field, index) => {
    return {
      ...field,
      ...watchPhone[index],
    };
  });

  const controlledEmails = emails.map((field, index) => {
    return {
      ...field,
      ...watchEmail[index],
    };
  });

  async function onSubmit(data: any) {
    setProgress(true);

    data.status = data.status === "true";
    data.profile = data.profile.map((item: string) =>
      profileSwitch(item, true),
    );

    try {
      await fetchData(`/users/${person._id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      set({ ...person, ...data });

      props.setIsEditing(false);
      snackbar?.open("Informações atualizadas com sucesso!", "success");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("name", {
          required: "Informe o nome do contato.",
        })}
        label="Nome"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.name ? true : false}
      />
      {errors?.name && (
        <Alert severity="error">{errors.name.message?.toString()}</Alert>
      )}

      <TextField
        {...register("login", {
          required: "Informe o login de acesso do contato.",
        })}
        label="Usuário de acesso"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.login ? true : false}
      />
      {errors?.login && (
        <Alert severity="error">{errors.login.message?.toString()}</Alert>
      )}

      <SelectChipField
        size="small"
        name="profile"
        register={register}
        get={getValues}
        required="Informe o perfil de acesso do contato."
        label="Perfil de acesso"
        error={errors?.profile ? true : false}
      >
        {profiles.map((profile) => (
          <MenuItem key={profile.value} value={profile.value}>
            {profile.value}
          </MenuItem>
        ))}
      </SelectChipField>
      {errors?.profile && (
        <Alert severity="error">{errors.profile.message?.toString()}</Alert>
      )}

      <TextField
        {...register("register")}
        label="RG"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
      />

      <TextField
        {...register("document", {
          required: "Informe o CPF do contato.",
        })}
        label="CPF"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.document ? true : false}
      />
      {errors?.document && (
        <Alert severity="error">{errors.document.message?.toString()}</Alert>
      )}

      <DatePickerField
        name="birthdate"
        register={register}
        get={getValues}
        set={setValue}
        label="Data de nascimento"
      />

      <Divider sx={{ my: 2 }} />

      <Grid container>
        <Grid item xs={12}>
          {controlledPhones.map((item, index) => (
            <Grid container key={index} alignItems="center" spacing={1}>
              <Grid item xs>
                <TextField
                  {...register(`phone.${index}.value` as const)}
                  label="Telefone"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                />
              </Grid>
              <Grid item xs>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Etiqueta"
                      size="small"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                    >
                      {phoneLabels.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                  name={`phone.${index}.label`}
                />
              </Grid>
              <Grid item xs="auto">
                <Button
                  size="small"
                  aria-label="Remover telefone"
                  onClick={() => {
                    removePhone(index);
                  }}
                  sx={{ mt: 1, p: 0, minWidth: "auto" }}
                  color="error"
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => addPhone({ label: "work", value: "" })}
            sx={{ mt: 1 }}
          >
            Adicionar Telefone <PhoneIcon sx={{ fontSize: 15, ml: 1 }} />
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container>
        <Grid item xs={12}>
          {controlledEmails.map((item, index) => (
            <Grid container key={index} alignItems="center" spacing={1}>
              <Grid item xs>
                <TextField
                  {...register(`email.${index}.value` as const)}
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                />
              </Grid>
              <Grid item xs>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Etiqueta"
                      size="small"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                    >
                      {contactLabels.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                  name={`email.${index}.label`}
                />
              </Grid>
              <Grid item xs="auto">
                <Button
                  size="small"
                  aria-label="Remover telefone"
                  onClick={() => {
                    removeEmail(index);
                  }}
                  sx={{ mt: 1, p: 0, minWidth: "auto" }}
                  color="error"
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => addEmail({ label: "work", value: "" })}
            sx={{ mt: 1 }}
          >
            Adicionar E-mail <EmailIcon sx={{ fontSize: 15, ml: 1 }} />
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 1 }}>
        <TextField
          {...register("address.zipcode")}
          label="CEP"
          variant="outlined"
          fullWidth
          size="small"
        />
      </Stack>

      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 1 }}>
        <TextField
          {...register("address.street")}
          label="Endereço"
          variant="outlined"
          fullWidth
          size="small"
        />

        <TextField
          {...register("address.number")}
          label="Número"
          variant="outlined"
          fullWidth
          size="small"
        />
      </Stack>

      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 1 }}>
        <TextField
          {...register("address.complement")}
          label="Complemento"
          variant="outlined"
          fullWidth
          size="small"
        />

        <TextField
          {...register("address.district")}
          label="Bairro"
          variant="outlined"
          fullWidth
          size="small"
        />
      </Stack>

      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 1 }}>
        <TextField
          {...register("address.city")}
          label="Cidade"
          variant="outlined"
          fullWidth
          size="small"
        />

        <TextField
          {...register("address.state")}
          label="Estado"
          variant="outlined"
          fullWidth
          size="small"
        />
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Situação"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            <MenuItem key="true" value="true">
              Ativo
            </MenuItem>
            <MenuItem key="false" value="false">
              Inativo
            </MenuItem>
          </TextField>
        )}
        control={control}
        name="status"
      />

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            props.setIsEditing(false);
          }}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Salvar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default Edit;
