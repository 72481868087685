import { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Alert,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../../assets/images/logoHorizontal.svg";

const PreviewScoreItem = ({
  number,
  color,
}: {
  number: number;
  color: string;
}) => {
  return (
    <Typography
      variant="caption"
      component="div"
      textAlign="center"
      fontWeight="bold"
      sx={{
        backgroundColor: color,
        p: "2px 10px",
        borderRadius: 1,
        color: "#FFF",
      }}
    >
      {number}
    </Typography>
  );
};

const NpsEditPreviewDrawer = ({
  state,
  setState,
  width,
  values,
}: {
  width: number | string;
  values: any;
  state: any;
  setState: any;
}) => {
  return (
    <>
      <Button
        size="small"
        disabled={state}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
      >
        Ver como está
      </Button>
      <Drawer variant="persistent" anchor="right" open={state}>
        <Box width={width} sx={{ p: 3, pt: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body2"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Pré-visualização
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setState(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ border: "1px solid #DDDDDD" }}>
            <Box sx={{ backgroundColor: "#e8f2fc", p: 3 }}>
              <img src={Logo} width={120} />
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ mt: 1 }}
                color="initial"
              >
                Sua opinião é essencial!
              </Typography>
            </Box>
            <Box sx={{ backgroundColor: "#FFF", p: 3 }}>
              <Typography variant="caption" component="div" color="initial">
                <b>Olá, NOME CONTATO.</b>
                <br />
                <br />
                Valorizamos sua opinião! Compartilhe sua visão sobre nossa
                empresa e nos ajude a melhorar nossos serviços. Sua contribuição
                é essencial para nós.
                {values.questions.length > 1 &&
                  " Clique no link abaixo e ajude-nos a oferecer a melhor experiência possível."}
              </Typography>
              {values.questions.length > 1 && (
                <Button variant="contained" size="small" sx={{ mt: 2 }}>
                  Responder pesquisa
                </Button>
              )}
              {values.questions.length > 0 && values.questions.length < 2 && (
                <Box marginTop={2}>
                  <Typography
                    variant="caption"
                    component="div"
                    textAlign="center"
                    fontWeight="bold"
                    color="initial"
                  >
                    {values.questions[0]}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{ mt: 2 }}
                    spacing="3px"
                  >
                    <PreviewScoreItem number={0} color="#FC495B" />
                    <PreviewScoreItem number={1} color="#FC495B" />
                    <PreviewScoreItem number={2} color="#FF624D" />
                    <PreviewScoreItem number={3} color="#FF8352" />
                    <PreviewScoreItem number={4} color="#FFAA4D" />
                    <PreviewScoreItem number={5} color="#FFD550" />
                    <PreviewScoreItem number={6} color="#E7E035" />
                    <PreviewScoreItem number={7} color="#DEF237" />
                    <PreviewScoreItem number={8} color="#AFEF41" />
                    <PreviewScoreItem number={9} color="#69D63E" />
                    <PreviewScoreItem number={10} color="#45E330" />
                  </Stack>
                </Box>
              )}
              {values.questions.length === 0 && (
                <Alert
                  severity="info"
                  sx={{ flexDirection: "column", alignItems: "center", mt: 2 }}
                >
                  A questão irá aparecer aqui.
                </Alert>
              )}
            </Box>
            <Box sx={{ backgroundColor: "#409cff", p: 3 }}>
              <Typography
                variant="caption"
                component="div"
                textAlign="center"
                color="#FFF"
              >
                <b>Para você contar com a gente!</b>
                <br />
                Não fique com dúvidas, envie um e-mail para
                consultoria@atlantico.digital.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default NpsEditPreviewDrawer;
