import { useEffect, useState } from "react";
import * as diacritics from "diacritics";
import { Alert, TextField, Typography } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import EditBox from "../../../../components/EditBox";

import { useMessage } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const MessageSMSContentBox = () => {
  const maxCharacterLimit = 140;
  const snackbar = useSnackbar();
  const { data, save } = useMessage();
  const [content, setContent] = useState("");

  const handleSaveContent = async () => {
    const payload = { sms_content: content };

    if (await save(payload)) {
      snackbar?.open("Conteúdo atualizado com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  useEffect(() => {
    setContent(data.sms_content);
  }, [data.sms_content]);

  const handleChange = (event: any) => {
    const rawValue = event.target.value;
    const valueWithoutSpecialChars = diacritics.remove(rawValue);

    if (valueWithoutSpecialChars.length <= maxCharacterLimit) {
      setContent(valueWithoutSpecialChars);
    }
  };

  return (
    <EditBox
      divider={false}
      state={!content ? false : true}
      title={`Conteúdo: ${content.length}/${maxCharacterLimit} caracteres`}
      icon={<CheckCircleIcon color={!content ? "disabled" : "success"} />}
      buttonText={`${content ? "Editar" : "Criar"} conteúdo`}
      text={
        <Typography variant="body2" color={"text.secondary"}>
          Projete o conteúdo do seu SMS.
        </Typography>
      }
      onSave={handleSaveContent}
      saveDisabled={!content}
    >
      <TextField
        multiline
        rows={4}
        label={`Digite até ${maxCharacterLimit} caracteres`}
        value={content}
        fullWidth
        inputProps={{
          maxLength: maxCharacterLimit,
        }}
        sx={{ whiteSpace: "pre-line" }}
        onChange={handleChange}
      />
      {!content && (
        <Alert severity="error" sx={{ mt: 1 }}>
          Defina o conteúdo para o SMS.
        </Alert>
      )}
    </EditBox>
  );
};

export default MessageSMSContentBox;
