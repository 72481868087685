import { useEffect } from "react";
import { AppRoutes } from "./app.routes";
import { PublicRoutes } from "./public.routes";
import { ClientRoutes } from "./client.routes";
import { useAuth } from "../contexts/AuthContext";

const Routes = () => {
  const auth = useAuth();

  useEffect(() => {
    auth?.tokenVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!auth?.user) {
    return <PublicRoutes />;
  }

  if (!auth?.user.is_admin) {
    return <ClientRoutes />;
  }

  return <AppRoutes />;
};

export default Routes;
