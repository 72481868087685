import { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Link,
  Typography,
  Stack,
} from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import CakeIcon from "@mui/icons-material/Cake";
import PlaceIcon from "@mui/icons-material/Place";

import { stringAvatar } from "../../../utils/stringAvatar";
import { formatDate } from "../../../utils";

import { usePerson } from ".";
import { useAuth } from "../../../contexts/AuthContext";

import Edit from "./edit";

const Profile = () => {
  const person = usePerson();
  const auth = useAuth();
  const { data } = person;
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.data._id]);

  return (
    <Card>
      <CardContent>
        {!isEditing && (
          <div style={{ textAlign: "center" }}>
            <Avatar
              {...stringAvatar(data.name)}
              style={{
                width: 60,
                height: 60,
                margin: "10px auto 10px auto",
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              {data.login}
            </Typography>
            <div>
              {data.profile.map((item: string) => (
                <Chip label={item} key={item} sx={{ m: 1 }} />
              ))}
            </div>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              {data.status && <VerifiedUserIcon color="success" />}
              {!data.status && <RemoveModeratorIcon color="error" />}
              <Typography
                variant="body1"
                color={`${data.status ? "success" : "error"}.main`}
                sx={{ ml: 1 }}
              >
                {data.status && "Ativo"}
                {!data.status && "Inativo"}
              </Typography>
            </Stack>

            <Divider sx={{ my: 3 }} />

            {data.phone && (
              <>
                {data.phone.length > 0 && data.phone[0].value != "" && (
                  <PhoneIcon color="primary" />
                )}
                {data.phone.map((item: any) => (
                  <Typography variant="body1" key={item.value}>
                    {item.value}
                  </Typography>
                ))}
              </>
            )}
            {data.email.length > 0 && data.email[0].value != "" && (
              <EmailIcon color="primary" sx={{ mt: 3 }} />
            )}
            {data.email.map((item: any) => (
              <div key={item.value}>
                <Link href={`mailto:${item.value}`}>{item.value}</Link>
              </div>
            ))}
            {data.birthdate && (
              <div>
                <CakeIcon color="primary" sx={{ mt: 3 }} />
                <Typography variant="body1">
                  {formatDate(data.birthdate, "D/M")}
                </Typography>
              </div>
            )}
            {data.address && data.address.zipcode && (
              <div>
                <PlaceIcon color="primary" sx={{ mt: 3 }} />
                <Typography variant="body1">
                  {data.address.street}, {data.address.number}
                  <br />
                  {data.address.complement && data.address.complement}
                  {data.address.complement && <br />}
                  {`${data.address.district} - ${data.address.city} - ${data.address.state}`}
                  {data.address.zipcode && <br />}
                  CEP: {data.address.zipcode}
                </Typography>
              </div>
            )}

            {auth?.user.permission_level === "manager" && (
              <>
                <Divider sx={{ my: 3 }} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
                </Button>
              </>
            )}
          </div>
        )}

        {isEditing && <Edit setIsEditing={setIsEditing} />}
      </CardContent>
    </Card>
  );
};

export default Profile;
