import { useEffect, useState } from "react";
import { formatDate, formatTimeAgo, formatHistoryText } from "../../../utils";

import {
  Divider,
  Chip,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Box,
  LinearProgress,
  CircularProgress,
  Typography,
  Alert,
  Paper,
  InputBase,
  IconButton,
  Modal,
  AlertTitle,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";

import SearchIcon from "@mui/icons-material/Search";
import NotesIcon from "@mui/icons-material/Notes";

import { fetchData } from "../../../fetchData";
import { useAuth } from "../../../contexts/AuthContext";
import { usePerson } from ".";
import { useSnackbar } from "../../../contexts/SnackbarContext";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const History = () => {
  const auth = useAuth();
  const person = usePerson();
  const snackbar = useSnackbar();
  const [history, setHistory] = useState("register");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingMoreHidden, setLoadingMoreHidden] = useState(false);
  const [data, setData] = useState<any>([]);
  const [limit, setLimit] = useState(5);
  const [skip, setSkip] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isRemovingNote, setIsRemovingNote] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    item: {
      registered_at: "",
      username: "",
      action: "",
      content: "",
    },
  });

  const handleHistoryChange = (event: any, newAlignment: string) => {
    setHistory(newAlignment);
    setSkip(0);
    setData([]);
    setLoadingMoreHidden(false);
  };

  const handleHistoryApi = async () => {
    setLoading(skip ? false : true);
    setError(false);
    let route = null;

    switch (history) {
      case "profile":
        route = `/users/${person.data._id}/logging?regex=login,recuper&limit=${limit}&skip=${skip}`;
        break;
      case "files":
        route = `/users/${person.data._id}/logging?regex=pasta,arquivo,ticket,download,cadastro&limit=${limit}&skip=${skip}`;
        break;
      case "search":
        route = `/users/${person.data._id}/logging?regex=${searchTerm}&limit=${limit}&skip=${skip}`;
        break;
      default:
        route = `/users/${person.data._id}/history?limit=${limit}&skip=${skip}`;
        break;
    }

    try {
      const result = await fetchData(route, { method: "GET" });

      if (skip) {
        if (result.length) {
          const newData = [...data, ...result];

          setData(newData);
        } else {
          setLoadingMoreHidden(true);
        }
      } else {
        setData(result);
      }
    } catch (error) {
      setError(true);
    }

    setLoadingMore(false);
    setLoading(false);
  };

  useEffect(() => {
    handleHistoryApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.data._id]);

  useEffect(() => {
    if (skip) {
      setLoadingMore(true);
    }

    handleHistoryApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, history, person.reload.state]);

  const handleLoadMore = () => {
    const newSkip = skip + limit;
    setSkip(newSkip);
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (searchTerm) {
      setSkip(0);
      handleHistoryApi();
    }
  };

  const handleModalClose = () => setModal({ ...modal, open: false });
  const handleModalOpen = (item: any) =>
    setModal({ ...modal, open: true, item });

  const handleRemoveNote = async (id: string) => {
    try {
      await fetchData(`/histories/${id}`, {
        method: "DELETE",
      });
      snackbar?.open("Nota removida com sucesso!", "success");
      person.reload.set(new Date());
    } catch (error) {
      snackbar?.open(
        "Ops! Ocorreu um erro ao remover a nota. Tente mais tarde.",
        "error",
      );
    }
  };

  const removeNote = (row: any) => {
    return (
      <div>
        {isRemovingNote != row._id && (
          <Button
            color="error"
            size="small"
            sx={{ p: 0, fontSize: 12 }}
            onClick={() => {
              setIsRemovingNote(row._id);
            }}
          >
            Excluir
          </Button>
        )}
        {isRemovingNote == row._id && (
          <ButtonGroup>
            <Button
              color="primary"
              size="small"
              sx={{ py: 0, fontSize: 12 }}
              onClick={() => {
                setIsRemovingNote(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              size="small"
              sx={{ py: 0, fontSize: 12 }}
              onClick={() => {
                handleRemoveNote(row._id);
              }}
            >
              Confirmar
            </Button>
          </ButtonGroup>
        )}
      </div>
    );
  };

  return (
    <div>
      <Divider sx={{ my: 3 }}>
        <Chip label="HISTÓRICO" />
      </Divider>
      <ToggleButtonGroup
        color="primary"
        value={history}
        exclusive
        fullWidth
        onChange={handleHistoryChange}
        size="small"
      >
        <ToggleButton value="register">Cadastro</ToggleButton>
        <ToggleButton value="files">Movimentação</ToggleButton>
        <ToggleButton value="profile">Acesso</ToggleButton>
        <ToggleButton value="search">Buscar</ToggleButton>
      </ToggleButtonGroup>

      {error && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          <AlertTitle>Ops!</AlertTitle>Ocorreu um erro inesperado, tente
          novamente mais tarde.
        </Alert>
      )}

      {loading && <LinearProgress sx={{ mt: 1 }} />}
      {!loading && history === "search" && (
        <Paper
          component="form"
          sx={{ display: "flex", mt: 2 }}
          onSubmit={handleSearchSubmit}
        >
          <InputBase
            sx={{ flex: 1, p: 1 }}
            placeholder="Digite o termo que procura aqui..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
        </Paper>
      )}
      {!loading && (
        <div>
          <Timeline position="alternate" sx={{ mb: 0 }}>
            {history == "register" && (
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                  {formatDate(person.data.created_at)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" />
                </TimelineSeparator>
                <TimelineContent>Pessoa criada</TimelineContent>
              </TimelineItem>
            )}

            {data.map((item: any) => {
              return (
                <TimelineItem key={item._id}>
                  <TimelineOppositeContent color="text.secondary">
                    {formatTimeAgo(item.registered_at)}
                    {history == "register" && (
                      <Typography variant="body2">
                        por {item.username}
                      </Typography>
                    )}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      onClick={() => handleModalOpen(item)}
                      sx={
                        item.isComment ? { backgroundColor: "note.main" } : {}
                      }
                    >
                      {item.isComment && (
                        <NotesIcon sx={{ color: "text.primary" }} />
                      )}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {history == "register" && item.content}
                    <Typography>
                      {item.action && formatHistoryText(item.action)}
                    </Typography>
                    {item.isComment &&
                      item.user === auth?.user._id &&
                      removeNote(item)}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      )}
      {data.length == 0 && !loading && !error && (
        <Alert
          severity="warning"
          sx={{ justifyContent: "center", borderRadius: 1, my: 0 }}
        >
          Nenhum histórico encontrado.
        </Alert>
      )}
      {!loading && data.length > 0 && (
        <Box sx={{ textAlign: "center" }}>
          <Button
            size="small"
            onClick={handleLoadMore}
            sx={{ display: loadingMoreHidden ? "none" : "inline-block" }}
          >
            {loadingMore && <CircularProgress size="1.5rem" />}
            {!loadingMore && "Carregar mais"}
          </Button>
        </Box>
      )}

      <Modal open={modal.open} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {formatDate(modal.item.registered_at)}
          </Typography>
          {history == "register" && (
            <Typography variant="body2">por {modal.item.username}</Typography>
          )}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, wordWrap: "break-word" }}
          >
            {modal.item.action}
            {history == "register" && modal.item.content}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default History;
