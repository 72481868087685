import { useEffect, useState } from "react";
import { MenuItem, Stack, TextField, Alert } from "@mui/material";
import { useSegment } from "..";

const triggers = [
  {
    label: "Mês é",
    value: "month is",
  },
  {
    label: "Dia é",
    value: "day is",
  },
  // {
  //   label: "É (dd/mm)",
  //   value: "is dd/mm",
  // },
  {
    label: "É Nulo",
    value: "date is null",
  },
  {
    label: "Não é nulo",
    value: "date is not null",
  },
];

const months = [
  {
    label: "Janeiro",
    value: 1,
  },
  {
    label: "Fevereiro",
    value: 2,
  },
  {
    label: "Março",
    value: 3,
  },
  {
    label: "Abril",
    value: 4,
  },
  {
    label: "Maio",
    value: 5,
  },
  {
    label: "Junho",
    value: 6,
  },
  {
    label: "Julho",
    value: 7,
  },
  {
    label: "Agosto",
    value: 8,
  },
  {
    label: "Setembro",
    value: 9,
  },
  {
    label: "Outubro",
    value: 10,
  },
  {
    label: "Novembro",
    value: 11,
  },
  {
    label: "Dezembro",
    value: 12,
  },
];

const SegmentFilterDateCondition = ({
  target,
  index,
}: {
  target: string;
  index: number;
}) => {
  const segment = useSegment();
  const [state, setState] = useState({
    condition: segment.data.filters[index].condition || "month is",
    value: segment.data.filters[index].value || "",
    error: false,
  });

  useEffect(() => {
    const newFilter = [...segment.data.filters];

    newFilter[index] = {
      ...newFilter[index],
      condition: state.condition,
      value: state.value,
      error: false,
    };

    segment.set({ ...segment.data, filters: newFilter });
  }, [state.value, state.condition]);

  useEffect(() => {
    setState({ ...state, error: segment.data.filters[index].error });
  }, [segment.data.filters[index].error]);

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        label="Condição"
        select
        value={state.condition}
        sx={{ flex: 1 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setState({ ...state, condition: e.target.value });
        }}
      >
        {triggers.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {state.condition === "month is" && (
        <TextField
          label="valor"
          select
          value={state.value}
          error={state.error}
          sx={{ flex: 1 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, value: e.target.value });
          }}
        >
          {months.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {(state.condition === "day is" || state.condition === "is dd/mm") && (
        <TextField
          label="valor"
          value={state.value}
          error={state.error}
          sx={{ flex: 1 }}
          helperText={
            state.condition === "is dd/mm"
              ? "Coloque a data no formato dd/mm"
              : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, value: e.target.value });
          }}
        ></TextField>
      )}
      {state.error && (
        <Alert severity="error" sx={{ alignItems: "center" }}>
          Insira um valor de campo de mesclagem
        </Alert>
      )}
    </Stack>
  );
};

export default SegmentFilterDateCondition;
