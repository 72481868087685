import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { fetchData } from "../../../../fetchData";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";
import SelectChipField from "../../../../components/SelectChipField";

import { profiles } from "../../../../utils/profileHandler";
import { profileSwitch } from "../../../../utils/profileHandler";
import {
  userProfile,
  organizationsProfileLabels,
} from "../../../../utils/labels";

type FormValues = {
  name: string;
  login: string;
  profile: string;
  permission_level: string;
  email: string;
};

const AddUser = () => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      profile: "",
      permission_level: "",
    },
  });

  const showError = () => {
    snackbar?.open(
      "Ops! No momento não foi possível criar o usuário.",
      "error",
    );
  };

  const handleLoginValidation = async (login: string) => {
    try {
      const check = await fetchData(`/users?login=${login}`, {
        method: "GET",
      });

      if (check.count) {
        snackbar?.open(
          "O nome de usuário escolhido já existe no sistema.",
          "warning",
        );
        return true;
      }
    } catch (error) {
      showError();
      return true;
    }
    return false;
  };

  async function onSubmit(data: any) {
    data.profile = [data.profile];
    data.is_admin = true;
    data.email = [
      {
        value: data.email,
        label: "work",
        primary: true,
      },
    ];

    setProgress(true);

    const validateLogin = await handleLoginValidation(data.login);

    if (validateLogin) {
      setProgress(false);
      return;
    }

    try {
      const user = await fetchData(`/users`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      snackbar?.open("Usuário criado com sucesso!", "success");

      navigate(`/users`);
      modal?.close();
    } catch (error) {
      showError();
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("name", {
          required: "Informe o nome do usuário.",
        })}
        label="Nome"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.name ? true : false}
      />
      {errors?.name && (
        <Alert severity="error">{errors.name.message?.toString()}</Alert>
      )}

      <TextField
        {...register("login", {
          required: "Informe o login de acesso do usuário.",
        })}
        label="Usuário de acesso"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.login ? true : false}
      />
      {errors?.login && (
        <Alert severity="error">{errors.login.message?.toString()}</Alert>
      )}

      <TextField
        {...register("email", {
          required: "Informe o e-mail do usuário.",
        })}
        label="E-mail"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.email ? true : false}
      />
      {errors?.email && (
        <Alert severity="error">{errors.email.message?.toString()}</Alert>
      )}

      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Perfil de acesso"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            {userProfile.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name="permission_level"
      />
      {errors?.permission_level && (
        <Alert severity="error">
          {errors.permission_level.message?.toString()}
        </Alert>
      )}

      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Carteira"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            {organizationsProfileLabels.map((label: string) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name="profile"
      />

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={modal?.close}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Criar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default AddUser;
