import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style/quill-override.css";

import { Alert, TextField, Typography } from "@mui/material";
import EditBox from "../../../../components/EditBox";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useMessage } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const MessageEmailContentBox = () => {
  const snackbar = useSnackbar();
  const { data, save } = useMessage();
  const [content, setContent] = useState("");

  const handleSaveContent = async () => {
    const payload = { content };

    if (await save(payload)) {
      snackbar?.open("Conteúdo atualizado com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  useEffect(() => {
    setContent(data.content);
  }, [data.content]);

  return (
    <EditBox
      divider={false}
      state={!content ? false : true}
      title="Conteúdo"
      icon={<CheckCircleIcon color={!content ? "disabled" : "success"} />}
      buttonText={`${content ? "Editar" : "Criar"} conteúdo`}
      text={
        <Typography variant="body2" color={"text.secondary"}>
          Projete o conteúdo do seu e-mail.
        </Typography>
      }
      onSave={handleSaveContent}
      saveDisabled={!content}
    >
      <ReactQuill theme="snow" value={content} onChange={setContent} />

      {!content && (
        <Alert severity="error" sx={{ mt: 1 }}>
          Defina o conteúdo para o e-mail.
        </Alert>
      )}
    </EditBox>
  );
};

export default MessageEmailContentBox;
