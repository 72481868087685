import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { fetchData } from "../../../../fetchData";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";

type FormValues = {
  title: string;
  email: boolean;
  sms: boolean;
  whatsapp: boolean;
};

const AddMessage = () => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: false,
      sms: false,
      whatsapp: false,
    },
  });

  const showError = () => {
    snackbar?.open(
      "Ops! No momento não foi possível criar o comunicado.",
      "error",
    );
  };

  async function onSubmit(data: any) {
    const payload: {
      title: string;
      method?: Array<string>;
    } = {
      title: data.title,
      method: [],
    };

    if (payload.method) {
      if (data.email) payload.method.push("email");
      if (data.sms) payload.method.push("sms");
      if (data.whatsapp) payload.method.push("whatsapp");
      if (!payload.method.length) delete payload.method;
    }

    setProgress(true);

    try {
      const message = await fetchData(`/messaging`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      snackbar?.open("Comunicado criado com sucesso!", "success");

      navigate(`/messaging/edit/${message._id}`);
      modal?.close();
    } catch (error) {
      showError();
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("title", {
          required: "Informe o título do comunicado.",
        })}
        label="Título"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.title ? true : false}
      />
      {errors?.title && (
        <Alert severity="error">{errors.title.message?.toString()}</Alert>
      )}

      <Stack direction="row" spacing={1}>
        <Controller
          render={({ field }) => (
            <FormControlLabel {...field} control={<Switch />} label="E-mail" />
          )}
          control={control}
          name="email"
        />
        <Controller
          render={({ field }) => (
            <FormControlLabel {...field} control={<Switch />} label="SMS" />
          )}
          control={control}
          name="sms"
        />
        <Controller
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={<Switch />}
              label="WhatsApp"
            />
          )}
          control={control}
          name="whatsapp"
        />
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={modal?.close}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Criar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default AddMessage;
