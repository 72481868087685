import { useState } from "react";
import { useTheme } from "@mui/material";
import {
  Divider,
  Stack,
  Paper,
  Typography,
  IconButton,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useSegment } from ".";
import SegmentFilterDefaultCondition from "./filters/default";
import SegmentFilterOptionsCondition from "./filters/options";
import SegmentFilterDateCondition from "./filters/date";

const FilterComponent = ({ filter, index }: { filter: any; index: number }) => {
  switch (filter.target) {
    case "city":
    case "state":
    case "reference":
      return (
        <SegmentFilterDefaultCondition target={filter.target} index={index} />
      );
    case "birthdate":
      return (
        <SegmentFilterDateCondition target={filter.target} index={index} />
      );
    case "profile":
    case "person_profile":
    case "regime":
    case "area":
      return (
        <SegmentFilterOptionsCondition target={filter.target} index={index} />
      );

    default:
      return <></>;
  }
};

const Filters = () => {
  const segment = useSegment();
  const {
    palette: { mode },
  } = useTheme();

  const handleConditionChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    segment.set({ ...segment.data, condition: newAlignment });
  };

  const handleDuplicateFilter = (filter: any) => {
    const newFilter = [...segment.data.filters];

    newFilter.push(filter);

    segment.set({ ...segment.data, filters: newFilter });
  };

  const handleRemoveFilter = (item: number) => {
    const newFilter = [...segment.data.filters];

    segment.set({
      ...segment.data,
      filters: newFilter.filter((filter: any, index: number) => item !== index),
    });
  };

  return (
    <>
      {segment.data.filters.map((filter: any, index: number) => (
        <Box key={`filter-${index}-${filter.target}`}>
          <Paper
            elevation={3}
            variant={mode === "light" ? "outlined" : "elevation"}
            sx={{ mb: 2 }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ p: 1 }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                flex={1}
                sx={{ ml: 1 }}
              >
                {filter.label}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => {
                    handleDuplicateFilter(filter);
                  }}
                >
                  <ControlPointDuplicateIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleRemoveFilter(index);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Box>
            </Stack>
            <Divider />
            <Box padding={3}>
              <FilterComponent filter={filter} index={index} />
            </Box>
          </Paper>
          {segment.data.filters.length > 1 &&
            segment.data.filters.length !== index + 1 && (
              <ToggleButtonGroup
                color="primary"
                value={segment.data.condition}
                exclusive
                onChange={handleConditionChange}
                aria-label="Condition"
              >
                <ToggleButton value="and">E</ToggleButton>
                <ToggleButton value="or">Ou</ToggleButton>
              </ToggleButtonGroup>
            )}
        </Box>
      ))}
    </>
  );
};

export default Filters;
