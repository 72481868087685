import { useEffect, useState } from "react";
import {
  Alert,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import EditBox from "../../../../components/EditBox";

import { useMessage } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const MessageMethodBox = () => {
  const snackbar = useSnackbar();
  const { data, save } = useMessage();
  const [method, setMethod] = useState({
    email: false,
    sms: false,
    whatsapp: false,
  });

  useEffect(() => {
    setMethod({
      email: data.method ? data.method.includes("email") : false,
      sms: data.method ? data.method.includes("sms") : false,
      whatsapp: data.method ? data.method.includes("whatsapp") : false,
    });
  }, [data]);

  const handleChangeMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod({ ...method, [event.target.value]: event.target.checked });
  };

  const handleSaveMethod = async () => {
    const newMethod = [];

    if (method.email) newMethod.push("email");
    if (method.sms) newMethod.push("sms");
    if (method.whatsapp) newMethod.push("whatsapp");

    const payload = { method: newMethod };

    if (await save(payload)) {
      snackbar?.open("Meio de comunicação atualizado com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  return (
    <EditBox
      divider={data.method.length === 0 ? false : true}
      state={data.method.length === 0 ? false : true}
      title="Meio de comunicação"
      icon={
        <CheckCircleIcon
          color={data.method.length === 0 ? "disabled" : "success"}
        />
      }
      saveDisabled={!method.email && !method.sms && !method.whatsapp}
      buttonText={`${data.method.length > 0 ? "Editar" : "Definir"} veiculação`}
      text={
        data.method.length > 0 ? (
          <Stack direction="row" spacing={1}>
            {data.method.includes("email") && (
              <Chip
                color="primary"
                variant="outlined"
                label="E-mail"
                size="small"
              />
            )}
            {data.method.includes("sms") && (
              <Chip
                color="primary"
                variant="outlined"
                label="SMS"
                size="small"
              />
            )}
            {data.method.includes("whatsapp") && (
              <Chip
                color="primary"
                variant="outlined"
                label="WhatsApp"
                size="small"
              />
            )}
          </Stack>
        ) : (
          <Typography variant="body2" color={"text.secondary"}>
            Quais serão os canais de envio para esta comunicação?
          </Typography>
        )
      }
      helperText={
        <Typography variant="body2" color={"text.secondary"}>
          Quais serão os canais de envio para esta comunicação?
        </Typography>
      }
      onSave={handleSaveMethod}
    >
      <Stack direction="row" spacing={1}>
        <FormControlLabel
          control={
            <Switch
              value="email"
              checked={method.email}
              onChange={handleChangeMethod}
            />
          }
          label="E-mail"
        />
        <FormControlLabel
          control={
            <Switch
              value="sms"
              checked={method.sms}
              onChange={handleChangeMethod}
            />
          }
          label="SMS"
        />
        <FormControlLabel
          control={
            <Switch
              value="whatsapp"
              checked={method.whatsapp}
              onChange={handleChangeMethod}
            />
          }
          label="WhatsApp"
        />
      </Stack>
      {!method.email && !method.sms && !method.whatsapp && (
        <Alert severity="error" sx={{ mt: 1 }}>
          Defina ao menos um meio de comunicação.
        </Alert>
      )}
    </EditBox>
  );
};

export default MessageMethodBox;
