import { useMemo, useState } from "react";
import { SnackbarContext } from "../contexts/SnackbarContext";

export default function SnackbarProvider({ children }: any) {
  const [state, setState] = useState({
    open: false,
    closeButton: true,
    message: "",
    severity: "warning",
  });

  // call this function when you want to open the snackbar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const open = async (message: any, severity: any) => {
    setState({
      ...state,
      open: true,
      message,
      severity: severity || "warning",
    });
  };

  // call this function when you want to close the snackbar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const close = () => {
    setState({ ...state, open: false, message: "" });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hideCloseButton = async (buttonState: boolean) => {
    setState({ ...state, closeButton: buttonState });
  };

  const value = useMemo(
    () => ({
      state,
      open,
      close,
      hideCloseButton,
    }),
    [close, hideCloseButton, open, state],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}
