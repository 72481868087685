import { createContext, useContext } from "react";

interface State {
  state?: any;
  open: (message: string, severity?: string) => void;
  close: () => void;
  hideCloseButton: (buttonState: boolean) => void;
}

export const SnackbarContext = createContext<State | null>(null);

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
