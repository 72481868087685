import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CircularProgress,
  Button,
  Snackbar,
  TextField,
  Alert,
  Typography,
} from "@mui/material";

const ForgetPassword = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbartxt, setSnackbarTxt] = useState("");
  const [sended, setSended] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // recoverPassword();
    }
  };

  // const recoverPassword = async () => {};

  return (
    <React.Fragment>
      {sended ? (
        <>
          <Typography
            variant="h6"
            component="div"
            fontFamily="Roboto"
            gutterBottom
            sx={{ fontWeight: "400", marginBottom: "0" }}
          >
            Um link de redefinição de senha foi enviado para o seu e-mail.
          </Typography>
          <Button
            fullWidth={true}
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => props.history.push(`/`)}
            size="large"
            style={{ marginTop: "8px" }}
          >
            Voltar para o login
          </Button>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            component="div"
            fontFamily="Roboto"
            gutterBottom
            sx={{ fontWeight: "400", marginBottom: "0" }}
          >
            Informe o email associado à sua conta.
          </Typography>
          <TextField
            label="E-mail"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
            color="primary"
            fullWidth={true}
            margin="dense"
          />
          <Button
            fullWidth={true}
            color="primary"
            variant="contained"
            disabled={loading}
            // onClick={(_) => recoverPassword()}
            size="large"
            style={{ marginTop: "8px" }}
          >
            {loading && <CircularProgress size={24} />}
            {!loading && "Enviar"}
          </Button>

          <Button
            fullWidth={true}
            color="primary"
            onClick={() => navigate(`/`)}
            size="large"
            style={{ marginTop: "8px" }}
          >
            Voltar
          </Button>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbar}
            autoHideDuration={6000}
            onClose={() => setSnackbar(false)}
          >
            <Alert onClose={() => setSnackbar(false)} severity="warning">
              {snackbartxt}
            </Alert>
          </Snackbar>
        </>
      )}
    </React.Fragment>
  );
};

export default ForgetPassword;
