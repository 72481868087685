import { useEffect, useState } from "react";
import {
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
} from "@mui/material";
import { useSegment } from "..";
import {
  organizationsProfileLabels,
  organizationsRegimeTributarioLabels,
  organizationsAreasDeAtuacaoLabels,
} from "../../../../utils/labels";

import { profiles, profileSwitch } from "../../../../utils/profileHandler";

const SegmentFilterOptionsCondition = ({
  target,
  index,
}: {
  target: string;
  index: number;
}) => {
  const segment = useSegment();
  const [state, setState] = useState<{
    options: any[];
    value: string;
    condition: string;
    error: boolean;
  }>({
    options: [],
    condition: segment.data.filters[index].condition || "is",
    value: segment.data.filters[index].value || "",
    error: false,
  });

  useEffect(() => {
    let options: Array<any> = [];

    switch (target) {
      case "profile":
        options = organizationsProfileLabels;
        break;
      case "regime":
        options = organizationsRegimeTributarioLabels;
        break;
      case "area":
        options = organizationsAreasDeAtuacaoLabels;
        break;
      case "person_profile":
        options = profiles.map((row: any) => row.key);
        break;
    }
    setState({ ...state, options });
  }, []);

  useEffect(() => {
    const newFilter = [...segment.data.filters];

    newFilter[index] = {
      ...newFilter[index],
      value: state.value,
      condition: state.condition,
      error: false,
    };

    segment.set({ ...segment.data, filters: newFilter });
  }, [state.value, state.condition]);

  useEffect(() => {
    if (state.options.length)
      setState({ ...state, error: segment.data.filters[index].error });
  }, [segment.data.filters[index].error]);

  return (
    <Stack direction="row" spacing={2}>
      <ToggleButtonGroup
        color="primary"
        value={state.condition}
        exclusive
        onChange={(e: React.MouseEvent<HTMLElement>, newAlignment: string) => {
          setState({ ...state, condition: newAlignment, error: false });
        }}
        aria-label="Condition"
      >
        <ToggleButton value="is">É</ToggleButton>
        <ToggleButton value="is not">Não é</ToggleButton>
      </ToggleButtonGroup>
      {state.options.length && (
        <TextField
          label="Escolha"
          select
          error={state.error}
          value={state.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, value: e.target.value, error: false });
          }}
          sx={{ flex: 1 }}
        >
          {state.options.map((option) => (
            <MenuItem key={option} value={option}>
              {target === "person_profile" ? profileSwitch(option) : option}
            </MenuItem>
          ))}
        </TextField>
      )}
      {state.error && (
        <Alert severity="error" sx={{ alignItems: "center" }}>
          Insira um valor de campo de mesclagem
        </Alert>
      )}
    </Stack>
  );
};

export default SegmentFilterOptionsCondition;
