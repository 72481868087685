import { useState, useEffect } from "react";
import { Card, Tabs, Tab, Box } from "@mui/material";

import DomainIcon from "@mui/icons-material/Domain";
import LinkIcon from "@mui/icons-material/Link";
import GamepadIcon from "@mui/icons-material/Gamepad";

import PersonOrganizationsTab from "./tabs/organizations";
import PersonActionsTab from "./tabs/actions";
import PersonIntegrationsTab from "./tabs/integrations";

import { usePerson } from ".";

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const TabsCard = () => {
  const [value, setValue] = useState(0);
  const person = usePerson();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.data._id]);

  return (
    <Card>
      <Box
        sx={{
          borderRadius: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Ações"
            icon={<GamepadIcon />}
            iconPosition="start"
            sx={{ minHeight: 50 }}
          />
          <Tab
            label="Organizações"
            icon={<DomainIcon />}
            iconPosition="start"
            sx={{ minHeight: 50 }}
          />
          {/* <Tab
            label="Integrações"
            icon={<LinkIcon />}
            iconPosition="start"
            sx={{ minHeight: 50 }}
          /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ p: 0 }}>
        <PersonActionsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PersonOrganizationsTab />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <PersonIntegrationsTab />
      </TabPanel> */}
    </Card>
  );
};

export default TabsCard;
