import { Outlet } from "react-router-dom";
import { Container, Box, Button } from "@mui/material";

import logo from "../../assets/images/symbol.svg";
import Bg from "../../assets/images/signinBackground.jpg";

import { useAuth } from "../../contexts/AuthContext";

function Client() {
  const auth = useAuth();

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.main",
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <Box
          boxShadow={3}
          sx={{
            backgroundColor: "background.default",
            borderRadius: "1em",
            padding: "5em",
            textAlign: "center",
          }}
        >
          <img
            src={logo}
            width="60"
            style={{ marginBottom: "40px" }}
            alt="Atlântico Digital"
          ></img>
          <Outlet />
          <Button
            fullWidth={true}
            color="error"
            variant="contained"
            size="large"
            sx={{ mt: 1 }}
            onClick={auth?.logout}
          >
            Logout
          </Button>
        </Box>
      </Container>
    </Container>
  );
}

export default Client;
