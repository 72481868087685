import { useEffect, useState } from "react";
import { Alert, TextField, Typography } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import EditBox from "../../../../components/EditBox";

import { useMessage } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const MessageHeaderTextBox = () => {
  const snackbar = useSnackbar();
  const { data, save } = useMessage();
  const [headerText, setHeaderText] = useState("");

  const handleSaveHeaderText = async () => {
    const payload = { header_text: headerText };

    if (await save(payload)) {
      snackbar?.open("Chamada atualizada com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  useEffect(() => {
    setHeaderText(data.header_text);
  }, [data.header_text]);

  return (
    <EditBox
      state={!headerText ? false : true}
      title="Chamada principal"
      icon={<CheckCircleIcon color={!headerText ? "disabled" : "success"} />}
      buttonText={`${headerText ? "Editar" : "Adicionar"} chamada`}
      text={
        <Typography
          variant={headerText ? "body1" : "body2"}
          color={headerText ? "text.primary" : "text.secondary"}
        >
          {headerText
            ? headerText
            : "Qual destaque ou informação vital você pretende incluir na chamada?"}
        </Typography>
      }
      helperText={
        <Typography variant="body2" color={"text.secondary"}>
          Qual destaque ou informação vital você pretende incluir na chamada?
        </Typography>
      }
      onSave={handleSaveHeaderText}
      saveDisabled={!headerText}
    >
      <TextField
        label="Texto da Chamada"
        value={headerText}
        fullWidth
        onChange={(e) => {
          setHeaderText(e.target.value);
        }}
      />
      {!headerText && (
        <Alert severity="error" sx={{ mt: 1 }}>
          Defina a chamada para o e-mail.
        </Alert>
      )}
    </EditBox>
  );
};

export default MessageHeaderTextBox;
