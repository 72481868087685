import { createContext, useContext } from "react";

interface State {
  user?: any;
  logout: () => void;
  login: (data: any) => Promise<any>;
  tokenVerify: () => void;
}

export const AuthContext = createContext<State | null>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};
