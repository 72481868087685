import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useNps } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const NpsEditTitleBox = () => {
  const snackbar = useSnackbar();
  const { data, save } = useNps();
  const [state, setState] = useState({
    value: "",
    error: false,
    progress: false,
    editing: false,
  });

  useEffect(() => {
    setState({ ...state, value: data.title });
  }, [data.title]);

  const handleSubmit = async () => {
    const payload = { title: state.value };
    setState({ ...state, progress: true });

    if (await save(payload)) {
      setState({ ...state, editing: false, progress: false });
      snackbar?.open("Título atualizado com sucesso!", "success");
    } else {
      setState({ ...state, progress: false });
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  return (
    <Box maxWidth={500}>
      {!state.editing && (
        <>
          <Typography variant="h4">{data.title}</Typography>
          <Link
            component="button"
            sx={{ p: 0 }}
            onClick={() => {
              setState({ ...state, editing: true });
            }}
          >
            Editar nome
          </Link>
        </>
      )}

      {state.editing && (
        <Stack direction="column" spacing={1}>
          <TextField
            size="small"
            fullWidth
            value={state.value}
            name="title"
            placeholder="Dê um nome à pesquisa"
            error={state.error}
            onChange={(e: any) => {
              setState({
                ...state,
                value: e.target.value,
                error: !e.target.value,
              });
            }}
          />
          {state.error && (
            <Alert severity="error">Digite um nome para esta pesquisa</Alert>
          )}
          <Stack spacing={2} direction="row" justifyContent="flex-start">
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => {
                setState({ ...state, editing: false });
              }}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              {!state.progress && "Salvar"}
              {state.progress && (
                <CircularProgress size={12} sx={{ color: "#FFF" }} />
              )}
            </Button>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default NpsEditTitleBox;
