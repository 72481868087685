import Client from "../layouts/Client/Client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "../views/Client/Dashboard";

export const ClientRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Client />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
