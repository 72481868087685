import {
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
  Typography,
  ListSubheader,
} from "@mui/material";

import EditBox from "../../../../components/EditBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { fetchData } from "../../../../fetchData";
import { useMessage } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useEffect, useState } from "react";

const MessageSegmentBox = () => {
  const { data, save } = useMessage();
  const snackbar = useSnackbar();
  const [state, setState] = useState(0);
  const [progress, setProgress] = useState(true);
  const [segment, setSegment] = useState("all");
  const [segmentTitle, setSegmentTitle] = useState("");
  const [segments, setSegments] = useState([]);

  const report = async () => {
    try {
      let url = "report";

      if (data.segment) {
        url = `${data.segment}/list`;
      }

      const response = await fetchData(`/segments/${url}`, { method: "GET" });

      setState(response.total);
      setProgress(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSegment = async () => {
    try {
      const response = await fetchData(`/segments/${data.segment}`, {
        method: "GET",
      });

      setSegmentTitle(response.title);
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    setProgress(true);

    try {
      const result = await fetchData(
        `/segments?sort=title&order=asc&limit=100&skip=0`,
        {
          method: "GET",
        },
      );

      if (result) {
        setSegments(result.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    report();
    if (data.segment) {
      setSegment(data.segment);
      getSegment();
    }
  }, [data.segment]);

  const handleChangeSegment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSegment(event.target.value);
  };

  const handleSaveSegment = async () => {
    setProgress(true);
    const payload = {
      segment: segment === "all" ? null : segment.toString(),
    };

    if (await save(payload)) {
      snackbar?.open("Segmento atualizado com sucesso!", "success");
      init();
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  return (
    <EditBox
      onSave={handleSaveSegment}
      state={true}
      title="Para"
      icon={<CheckCircleIcon color="success" />}
      disabled={progress}
      buttonText="Editar segmentação"
      text={
        <>
          {!progress &&
            `${
              !data.segment
                ? "Todos os contatos da base Atlântico"
                : segmentTitle
            }.`}{" "}
          {progress && <CircularProgress size={15} />}
          <Typography
            variant="body2"
            color="primary"
            component="span"
            fontWeight="bold"
          >
            {!progress && state} {!progress && `destinatários.`}
          </Typography>
        </>
      }
    >
      <TextField
        id="segments"
        select
        label="Enviar para"
        fullWidth
        value={segment}
        onChange={handleChangeSegment}
      >
        <MenuItem value="all">Todos os contatos da base</MenuItem>
        <ListSubheader color="primary" sx={{ borderTop: "1px solid #CCC " }}>
          SEGMENTOS
        </ListSubheader>
        {segments.map((option: any) => (
          <MenuItem key={option._id} value={option._id}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>
    </EditBox>
  );
};

export default MessageSegmentBox;
