import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Link,
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
  Chip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SimCardIcon from "@mui/icons-material/SimCard";

import { navigateToOrganization } from "../../../../utils/navigateToOrganization";

function orderByReference(a: any, b: any) {
  return a.reference - b.reference;
}

const A1Expiricy = ({ total, data }: { total: number; data: any }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "reference",
      headerName: "Cliente",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            onClick={() => {
              navigateToOrganization(params.row.reference, navigate);
            }}
          >
            {params.row.reference}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Certificado",
      flex: 1,
      renderCell: (params: any) => {
        if (!params.row.digital_certificate?.expiricy) return "Não possui";

        const now = new Date();
        const expiricy = new Date(params.row.digital_certificate?.expiricy);

        if (now > expiricy) {
          return "Expirou";
        } else {
          return "Expira em breve";
        }
      },
    },
  ];

  useEffect(() => {
    data.sort(orderByReference);
  }, []);

  return (
    <>
      <Chip
        onClick={() => {
          setState(true);
        }}
        icon={<SimCardIcon />}
        label={total}
        variant="filled"
        color="error"
        sx={{ fontWeight: "bold" }}
      />
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Certificados Digitais
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            {data.length > 0 && (
              <DataGrid
                rows={data}
                columns={columns}
                getRowId={(row) => row._id}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                hideFooter
                columnHeaderHeight={0}
                sx={{ flex: 1 }}
              />
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default A1Expiricy;
