import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Autocomplete,
  ButtonGroup,
  Tooltip,
  Box,
  Alert,
  LinearProgress,
  Link,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { usePerson } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useAuth } from "../../../../contexts/AuthContext";
import { fetchData } from "../../../../fetchData";

import pad from "../../../../utils/pad";

const PersonOrganizationsTab = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { data } = usePerson();
  const snackbar = useSnackbar();
  const [progress, setProgress] = useState(true);
  const [organizationsError, setOrganizationsError] = useState(false);
  const [organizations, setOrganizations] = useState<object[]>([]);
  const [organizationsResults, setOrganizationsResults] = useState([]);
  const [organizationsResultsLoading, setOrganizationsResultsLoading] =
    useState(true);
  const [isRemovingOrganization, setIsRemovingOrganization] = useState(null);

  const updatePersonOrganizations = async (payload: any) => {
    try {
      await fetchData(`/users/${data._id}/organizations`, {
        method: "PUT",
        body: JSON.stringify(
          payload.map((organization: any) => Number(organization.reference)),
        ),
      });

      snackbar?.open("Organizações atualizadas com sucesso!", "success");
      return true;
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível atualizar as organizações.",
        "error",
      );
      return false;
    }
  };

  const handleAddOrganization = (e: any, organization: any) => {
    if (!organization) return;
    const payload = [
      ...organizations,
      {
        _id: organization._id,
        name: organization.name,
        reference: organization.reference,
      },
    ];

    setOrganizations(payload);
    updatePersonOrganizations(payload);
  };

  const handleRemoveOrganization = (reference: number) => {
    const payload = organizations.filter(
      (organization: any) => organization.reference != reference,
    );

    setOrganizations(payload);
    updatePersonOrganizations(payload);
  };

  const fetchOrganizations = async (limit: number, skip: number) => {
    try {
      const result = await fetchData(
        `/organizations?sort=name&limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      setOrganizationsResults(result.rows);
    } catch (error) {
      console.log(error);
    }

    setOrganizationsResultsLoading(false);
  };

  const init = async () => {
    try {
      const person = await fetchData(`/users/${data._id}/organizations`, {
        method: "GET",
      });

      if (person.length) {
        setOrganizations(person[0].organizations);
      }
    } catch (error) {
      setOrganizationsError(true);
    }

    setProgress(false);
  };

  useEffect(() => {
    init();
    fetchOrganizations(1000, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data._id]);

  if (progress) {
    return <LinearProgress sx={{ m: 2 }} />;
  }

  return (
    <>
      {organizationsError && (
        <Box sx={{ p: 3 }}>
          <Alert icon={false} color="error" sx={{ justifyContent: "center" }}>
            Ops! No momento não foi possível carregar as organizações
            relacionadas a esta pessoa.
          </Alert>
        </Box>
      )}

      {!organizationsError && (
        <Box sx={{ p: 2 }}>
          {organizations.length > 0 && (
            <TableContainer sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={80}>
                      Código
                    </TableCell>
                    <TableCell>Organização</TableCell>
                    <TableCell align="center" width={80}>
                      <MoreVertIcon />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {organizations.map((row: any) => (
                    <TableRow
                      key={row.reference}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="center">{row.reference}</TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          component="button"
                          onClick={() => {
                            navigate(`/organizations/${row._id}`);
                          }}
                        >
                          {row.name}
                        </Link>
                      </TableCell>
                      {auth?.user.permission_level === "manager" && (
                        <TableCell align="center">
                          {isRemovingOrganization != row.reference && (
                            <Tooltip title="Remover">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setIsRemovingOrganization(row.reference);
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {isRemovingOrganization == row.reference && (
                            <ButtonGroup>
                              <Tooltip title="Cancelar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setIsRemovingOrganization(null);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Confirmar">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    handleRemoveOrganization(row.reference);
                                  }}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                            </ButtonGroup>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {organizations.length === 0 && (
            <Alert
              icon={false}
              color="warning"
              sx={{ justifyContent: "center" }}
            >
              Nenhuma organização vinculada.
            </Alert>
          )}
          {auth?.user.permission_level === "manager" && (
            <Autocomplete
              sx={{ mt: 3 }}
              id="organization-pick"
              options={organizationsResults}
              loading={organizationsResultsLoading}
              getOptionLabel={(option: any) =>
                `${pad(option.reference, 4)} ${option.name}`
              }
              isOptionEqualToValue={(option, value) =>
                option.reference === value.reference
              }
              onChange={handleAddOrganization}
              noOptionsText="Nenhuma opção"
              loadingText="Carregando..."
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <AddCircleIcon sx={{ mr: 1 }} />{" "}
                  {`[${pad(option.reference, 4)}] ${option.name}`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Adicione uma organização"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default PersonOrganizationsTab;
