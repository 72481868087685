import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Button,
  Chip,
  Box,
  LinearProgress,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { fetchRRData } from "../../../../fetchRRData";
import { fetchData } from "../../../../fetchData";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID da tarefa",
    flex: 1,
    editable: true,
    renderCell: (params: any) => {
      return (
        <Stack
          direction="column"
          alignContent="flex-start"
          width="100%"
          spacing={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">{params.row.id}</Typography>
            <Box
              width={15}
              height={15}
              borderRadius={15}
              sx={{
                backgroundColor:
                  params.row?.delivery_result === undefined
                    ? "gray"
                    : params.row?.delivery_result
                    ? "green"
                    : "red",
              }}
            ></Box>
          </Stack>
          <Chip
            label={params.row.client_name}
            size="small"
            variant="outlined"
          />
        </Stack>
      );
    },
  },
];

const RRDeliveredTasks = ({ taskLog }: { taskLog: any }) => {
  const [state, setState] = useState(false);
  const [rrTasks, setRrTasks] = useState<any>([]);
  const [activityLogs, setActivityLogs] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  const filterByDate = (row: any) => {
    const taskDate = new Date(row.close_date);
    taskDate.setHours(0, 0, 0, 0);

    // console.log(taskDate, date, taskDate.valueOf() === date.valueOf());

    return taskDate.valueOf() === date.valueOf() && row.attachments_count > 0;
  };

  const init = async () => {
    setLoading(true);

    try {
      const queryTasks = await fetchRRData(
        `/tasks?is_closed=true&limit=200&sort=close_date&sort_dir=desc`,
        {
          method: "GET",
        },
      );

      const queryActivityLogs = await fetchData(
        `/histories/runrunit/tasks/acitivity/logs`,
        {
          method: "GET",
        },
      );

      setRrTasks(queryTasks.filter(filterByDate));
      setActivityLogs(queryActivityLogs);
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    setRrTasks(
      rrTasks.map((task: any) => ({
        ...task,
        delivery_result: activityLogs.some((row: any) => row.id === task.id),
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityLogs]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        sx={{ flex: 1 }}
        onClick={() => {
          init();
          setState(true);
        }}
      >
        Entregues hoje
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
          <Stack height="100%">
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Tarefas entregues hoje
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            {loading && <LinearProgress />}
            {!loading && (
              <DataGrid
                rows={rrTasks}
                rowHeight={75}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                sx={{ flex: 1 }}
              />
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default RRDeliveredTasks;
