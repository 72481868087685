import { useState } from "react";

import {
  Alert,
  Button,
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const RRTaskNotify = () => {
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [taskID, setTaskID] = useState<number | string>("");

  const onSubmit = async () => {
    if (!taskID) return;

    setLoading(true);
    setError(false);
    setSuccess(false);

    const response = await fetch(`https://api.atlantico.digital/oauth/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: "task:deliver",
        data: {
          task: {
            id: taskID,
          },
        },
      }),
    });

    if (response.ok) {
      setSuccess(true);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        sx={{ flex: 1 }}
        onClick={() => {
          setState(true);
          setTaskID("");
          setError(false);
          setSuccess(false);
        }}
      >
        Notificar
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Notificação de tarefa
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" spacing={1}>
              <TextField
                label="Número da tarefa"
                value={taskID}
                onChange={(e: any) => {
                  setTaskID(e.target.value);
                }}
                size="small"
                sx={{ flex: 1 }}
              />
              <Button size="small" variant="contained" onClick={onSubmit}>
                <KeyboardArrowRightIcon />
              </Button>
            </Stack>
            {loading && <LinearProgress />}
            {success && (
              <Alert
                severity="success"
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <Typography variant="body2" textAlign="center">
                  Cliente notificado com sucesso!
                </Typography>
              </Alert>
            )}
            {error && (
              <Alert
                severity="error"
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                icon="¯\_(ツ)_/¯"
              >
                <Typography variant="h6" textAlign="center" sx={{ mb: 1 }}>
                  Ops!
                </Typography>
                <Typography variant="body2" textAlign="center">
                  Não foi possível notificar o cliente desta tarefa, tente
                  novamente mais tarde.
                </Typography>
              </Alert>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default RRTaskNotify;
