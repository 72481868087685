import TextField from "@mui/material/TextField";
import { styled, alpha } from "@mui/material/styles";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor:
      theme.palette.mode === "light"
        ? alpha(theme.palette.common.white, 1)
        : alpha(theme.palette.common.black, 1),
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? alpha(theme.palette.common.white, 0.7)
          : alpha(theme.palette.common.black, 1),
    },
  },
  "& .MuiAutocomplete-input": {
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch !important",
      "&:focus": {
        width: "20ch !important",
      },
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiAutocomplete-endAdornment button": {
    transform: "none !important",
  },
}));
