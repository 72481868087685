import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import very_dissatisfied from "../../../assets/images/angry.svg";
import neutral from "../../../assets/images/neutral.svg";
import very_satisfied from "../../../assets/images/bigsmile.svg";

import {
  Alert,
  Button,
  Chip,
  Box,
  CircularProgress,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { useModal } from "../../../contexts/ModalContext";

const CommentModal = ({ comment }: { comment: string }) => {
  return <Box>{comment}</Box>;
};

const NpsScoreIcon = ({ type, number }: { type?: string; number: number }) => {
  let color = "error";
  let icon = very_dissatisfied;

  if (type === "passive") {
    color = "warning";
    icon = neutral;
  }

  if (type === "promoter") {
    color = "success";
    icon = very_satisfied;
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      color={color as any}
      label={number}
      icon={<img src={icon} width={18} />}
      sx={{ fontWeight: 700 }}
    />
  );
};

interface Props {
  label: string;
  icon: any;
  color: string;
  data: Array<object>;
}

const NpsScoreDrawer = ({ label, icon, color, data }: Props) => {
  const [state, setState] = useState(false);
  const modal = useModal();

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "Contato",
      flex: 1,
      editable: true,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="column"
            alignContent="flex-start"
            width="100%"
            spacing={1}
          >
            <Typography variant="body2">{params.row.user.name}</Typography>
            {params.row.answers.map((answer: any, index: number) => (
              <Stack
                direction="row"
                spacing={2}
                key={index}
                justifyContent="space-between"
              >
                <Box>
                  <NpsScoreIcon
                    number={answer.value}
                    type={
                      answer.value > 8
                        ? "promoter"
                        : answer.value > 6
                        ? "passive"
                        : ""
                    }
                  />
                  {answer.comment && (
                    <IconButton
                      sx={{ ml: 1 }}
                      size="small"
                      color="primary"
                      onClick={() => {
                        modal?.open(
                          params.row.user.name,
                          <CommentModal comment={answer.comment} />,
                        );
                      }}
                    >
                      <ChatBubbleOutlineIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Chip
                  label={params.row.client.reference}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            ))}
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Chip
        label={label}
        icon={icon}
        variant="outlined"
        sx={{ borderColor: color }}
        onClick={() => {
          setState(true);
        }}
      />
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
          <Stack height="100%">
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                {icon}
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  {label}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <DataGrid
              rows={data}
              rowHeight={75}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              sx={{ flex: 1 }}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default NpsScoreDrawer;
