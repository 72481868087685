import { useState, useEffect, useContext, createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Box,
  LinearProgress,
  CircularProgress,
  Card,
  CardContent,
  Backdrop,
  Tooltip,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";

import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { fetchData } from "../../../fetchData";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useModal } from "../../../contexts/ModalContext";

import NpsEditPreviewDrawer from "./drawer/preview";
import EditBox from "../../../components/EditBox";

import ViewContent from "../../../components/ViewContent";
import NpsEditSegmentBox from "./children/segment";
import NpsEditQuestionsBox from "./children/questions";
import NpsEditTitleBox from "./children/title";

export const NpsContext = createContext<{
  data: any;
  save: (data: any) => Promise<boolean>;
}>({
  data: {},
  save: async () => false,
});

export const useNps = () => {
  return useContext(NpsContext);
};

const NpsEdit = () => {
  const snackbar = useSnackbar();
  const modal = useModal();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [drawer, setDrawer] = useState(true);
  const drawerWidth = 450;
  const [error, setError] = useState(false);
  const [nps, setNps] = useState<any>({
    questions: [],
  });

  const init = async () => {
    try {
      const nps = await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "GET",
      });

      setNps(nps);
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataSave = async (data: any) => {
    const payload = { ...nps, ...data };

    try {
      await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      setNps(payload);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSendNps = async () => {
    setBackdrop(true);

    try {
      await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "PATCH",
      });

      snackbar?.open("Pesquisa enviada com sucesso!", "success");
      navigate(`/surveys/nps/${params.npsID}?q=success`);
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível enviar a pesquisa NPS.",
        "error",
      );
    }

    setBackdrop(false);
  };

  const handleDelete = async () => {
    modal?.close();
    setBackdrop(true);

    try {
      await fetchData(
        `/surveys/nps/${params.npsID}`,
        {
          method: "DELETE",
        },
        false,
      );

      snackbar?.open("Pesquisa excluída com sucesso!", "success");
      navigate(`/surveys/nps`);
    } catch (error) {
      console.log(error);
    }

    setBackdrop(false);
  };

  const DeleteConfirmationModal = () => {
    return (
      <Box>
        <Typography variant="body2" textAlign="center">
          A pesquisa <b>{nps.title}</b> será excluída.
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              modal?.close();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ flex: 1 }}
            onClick={() => {
              handleDelete();
            }}
          >
            SIM
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <NpsContext.Provider
      value={{
        data: nps,
        save: handleDataSave,
      }}
    >
      {backdrop && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box width={drawer ? `calc(100% - ${drawerWidth}px)` : "100%"}>
        <ViewContent
          title="NPS"
          label="Rascunho"
          icon={<DonutSmallIcon sx={{ mr: 2 }} />}
          button={
            !loading && (
              <>
                <Button
                  variant="text"
                  size="small"
                  color="white"
                  onClick={() => {
                    navigate("/surveys/nps");
                  }}
                >
                  Terminar mais tarde
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="quaternary"
                  disabled={nps.questions.length === 0}
                  onClick={() => {
                    handleSendNps();
                  }}
                >
                  Enviar
                </Button>
                <Tooltip title="Apagar Pesquisa">
                  <IconButton
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      modal?.open(
                        "Remover Pesquisa?",
                        <DeleteConfirmationModal />,
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        >
          <div>
            {loading && <LinearProgress sx={{ mt: 1 }} />}
            {!loading && (
              <>
                <NpsEditTitleBox />
                <Card sx={{ mt: 2 }}>
                  <CardContent>
                    <NpsEditSegmentBox />

                    <EditBox
                      state={true}
                      title="Meio de comunicação"
                      icon={<CheckCircleIcon color="success" />}
                      button={
                        <Button size="small" variant="outlined" disabled>
                          Editar veiculação
                        </Button>
                      }
                      text="E-mail"
                    />

                    <NpsEditQuestionsBox />

                    <EditBox
                      divider={false}
                      state={false}
                      title="Pré-visualização"
                      icon={
                        <CheckCircleIcon
                          color={drawer ? "success" : "disabled"}
                        />
                      }
                      button={
                        <NpsEditPreviewDrawer
                          state={drawer}
                          setState={setDrawer}
                          values={nps}
                          width={drawerWidth}
                        />
                      }
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </div>
        </ViewContent>
      </Box>
    </NpsContext.Provider>
  );
};

export default NpsEdit;
