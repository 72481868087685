import { forwardRef } from "react";
import {
  Dialog as MuiDialog,
  Card,
  CardContent,
  CardHeader,
  Stack,
  IconButton,
  Typography,
  Slide,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";

import { useDialog } from "../../contexts/DialogContext";

import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = () => {
  const dialog = useDialog();

  return (
    <MuiDialog
      open={dialog?.state.open}
      onClose={dialog?.close}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar position="fixed">
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            {dialog?.state.title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={dialog?.close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {dialog?.state.children}
    </MuiDialog>
  );
};

export default Dialog;
