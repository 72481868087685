import { useState, useEffect, createContext, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Box,
  LinearProgress,
  CircularProgress,
  Card,
  CardContent,
  Backdrop,
  Alert,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CampaignIcon from "@mui/icons-material/Campaign";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SmsIcon from "@mui/icons-material/Sms";
import DeleteIcon from "@mui/icons-material/Delete";

import ViewContent from "../../../components/ViewContent";
import EditBox from "../../../components/EditBox";
import MessagePreviewDrawer from "./drawer/preview";

import { fetchData } from "../../../fetchData";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useModal } from "../../../contexts/ModalContext";
import { useAuth } from "../../../contexts/AuthContext";

import MessageSegmentBox from "./children/segment";
import MessageMethodBox from "./children/method";
import MessageTitleBox from "./children/title";
import MessageSubjectBox from "./children/subject";
import MessageHeaderTextBox from "./children/header_text";
import MessageEmailContentBox from "./children/email_content";
import MessageSMSContentBox from "./children/sms_content";
import { formatDate } from "../../../utils";

export const MessageContext = createContext<{
  data: any;
  save: (data: any) => Promise<boolean>;
}>({
  data: {},
  save: async () => false,
});

export const useMessage = () => {
  return useContext(MessageContext);
};

const Message = () => {
  const drawerWidth = 450;
  const auth = useAuth();
  const modal = useModal();
  const snackbar = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [drawer, setDrawer] = useState(true);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [message, setMessage] = useState<any>({
    method: [],
    subject: "",
    header_text: "",
    content: "",
    sms_content: "",
  });

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const init = async () => {
    try {
      const response = await fetchData(`/messaging/${params.messageID}`, {
        method: "GET",
      });

      setMessage({ ...message, ...response });
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.messageID]);

  const handleDataSave = async (data: any) => {
    const payload = { ...message, ...data };

    try {
      await fetchData(`/messaging/${params.messageID}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      setMessage(payload);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSendMessage = async () => {
    modal?.close();
    setBackdrop(true);

    try {
      await fetchData(`/messaging/${params.messageID}/send`, {
        method: "GET",
      });

      snackbar?.open("Comunicado enviado com sucesso!", "success");
      setMessage({ ...message, state: true, sent_at: Date.now() });
    } catch (error) {
      console.log(error);
    }

    setBackdrop(false);
  };

  const handleDuplicate = async () => {
    setDuplicating(true);

    const payload = {
      ...message,
      title: `${message.title} (Cópia)`,
      state: false,
    };

    if (payload.sent_at) delete payload.sent_at;
    if (payload.bulk_id) delete payload.bulk_id;

    try {
      const response = await fetchData(`/messaging`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      navigate(`/messaging/${response._id}`);
    } catch (error) {
      console.log(error);
    }

    setDuplicating(false);
  };

  const handleDelete = async () => {
    modal?.close();
    setBackdrop(true);

    try {
      await fetchData(
        `/messaging/${params.messageID}`,
        {
          method: "DELETE",
        },
        false,
      );

      snackbar?.open("Comunicado excluído com sucesso!", "success");
      navigate(`/messaging`);
    } catch (error) {
      console.log(error);
    }

    setBackdrop(false);
  };

  const SendConfirmationModal = () => {
    return (
      <Box>
        <Typography variant="body2" textAlign="center">
          O comunicado <b>{message.title}</b> será enviado agora.
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              modal?.close();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ flex: 1 }}
            onClick={() => {
              handleSendMessage();
            }}
          >
            SIM
          </Button>
        </Stack>
      </Box>
    );
  };

  const DeleteConfirmationModal = () => {
    return (
      <Box>
        <Typography variant="body2" textAlign="center">
          O comunicado <b>{message.title}</b> será excluído.
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              modal?.close();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ flex: 1 }}
            onClick={() => {
              handleDelete();
            }}
          >
            SIM
          </Button>
        </Stack>
      </Box>
    );
  };

  if (error)
    return (
      <Alert severity="error">
        Ops! Ocorreu um erro inesperado, tente novamente mais tarde.
      </Alert>
    );

  return (
    <MessageContext.Provider
      value={{
        data: message,
        save: handleDataSave,
      }}
    >
      {backdrop && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box width={drawer ? `calc(100% - ${drawerWidth}px)` : "100%"}>
        <ViewContent
          title="Comunicado"
          label={!message.state ? "Rascunho" : ""}
          icon={<CampaignIcon sx={{ mr: 2 }} />}
          button={
            !loading &&
            auth?.user.permission_level === "manager" && (
              <>
                {!message.state && (
                  <>
                    <Button
                      variant="text"
                      size="small"
                      color="white"
                      onClick={() => {
                        navigate("/messaging");
                      }}
                    >
                      Terminar mais tarde
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="quaternary"
                      onClick={() => {
                        modal?.open(
                          "Enviar Comunicado?",
                          <SendConfirmationModal />,
                        );
                      }}
                    >
                      Enviar
                    </Button>
                    <Tooltip title="Apagar Comunicado">
                      <IconButton
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() => {
                          modal?.open(
                            "Remover Comunicado?",
                            <DeleteConfirmationModal />,
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {message.state && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="white"
                    onClick={() => {
                      handleDuplicate();
                    }}
                  >
                    {duplicating && (
                      <CircularProgress color="white" size={18} />
                    )}
                    {!duplicating && "Duplicar"}
                  </Button>
                )}
              </>
            )
          }
        >
          <div>
            {loading && <LinearProgress sx={{ mt: 1 }} />}
            {!loading && (
              <>
                <MessageTitleBox />
                <Card sx={{ mt: 2 }}>
                  <CardContent>
                    {!message.state && (
                      <>
                        <MessageSegmentBox />
                        <MessageMethodBox />
                        {message.method.includes("email") && (
                          <Accordion
                            expanded={expanded === "email"}
                            onChange={handleAccordionChange("email")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <EmailOutlinedIcon color="primary" />
                                <Typography
                                  variant="h6"
                                  fontWeight={500}
                                  color="primary"
                                >
                                  E-mail
                                </Typography>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                              <MessageSubjectBox />
                              <MessageHeaderTextBox />
                              <MessageEmailContentBox />
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {message.method.includes("sms") && (
                          <Accordion
                            expanded={expanded === "sms"}
                            onChange={handleAccordionChange("sms")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <SmsIcon color="primary" />
                                <Typography
                                  variant="h6"
                                  fontWeight={500}
                                  color="primary"
                                >
                                  SMS
                                </Typography>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                              <MessageSMSContentBox />
                            </AccordionDetails>
                          </Accordion>
                        )}

                        <Divider sx={{ my: 2 }} />
                      </>
                    )}

                    {message.state && (
                      <EditBox
                        editing={true}
                        divider={true}
                        state={true}
                        title="Comunicação enviada em"
                        helperText={
                          <Typography variant="body2" component="span">
                            {formatDate(message.sent_at)}
                          </Typography>
                        }
                        icon={<CheckCircleIcon color={"success"} />}
                      />
                    )}

                    <EditBox
                      divider={false}
                      state={drawer ? true : false}
                      title="Pré-visualização"
                      icon={
                        <CheckCircleIcon
                          color={drawer ? "success" : "disabled"}
                        />
                      }
                      button={
                        <MessagePreviewDrawer
                          expanded={expanded}
                          state={drawer}
                          setState={setDrawer}
                          handleChange={setExpanded}
                          width={drawerWidth}
                        />
                      }
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </div>
        </ViewContent>
      </Box>
    </MessageContext.Provider>
  );
};

export default Message;
