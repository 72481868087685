import { Outlet } from "react-router-dom";
import { Container, Box, Typography } from "@mui/material";

import logo from "../../assets/images/logoHorizontal.svg";
import Bg from "../../assets/images/signinPattern.webp";
import Snackbar from "../../components/Snackbar";

function Surveys() {
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.main",
        backgroundImage: `url(${Bg})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "center center",
        p: 0,
      }}
    >
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Box
          boxShadow={3}
          sx={{
            width: { xs: "100%", lg: 700 },
            backgroundColor: "background.default",
            borderTop: "10px solid",
            borderTopColor: "primary.main",
            borderRadius: ".5em",
            textAlign: "center",
          }}
        >
          <Box sx={{ py: 3, backgroundColor: "#FFF" }}>
            <img src={logo} width={180} alt="Atlântico"></img>
          </Box>
          <Box
            sx={{
              p: 5,
              borderTop: "1px solid #e7e7e7",
              borderBottom: "1px solid #e7e7e7",
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={{
              py: 3,
              backgroundColor: "#FFF",
              borderRadius: "0 0 .5em .5em",
            }}
          >
            <Typography
              variant="caption"
              textAlign="center"
              component="div"
              color="#999"
            >
              Atlântico © Desde 1973 apoiando você e sua empresa.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Snackbar />
    </Container>
  );
}

export default Surveys;
