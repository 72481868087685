import { useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import GroupIcon from "@mui/icons-material/GroupOutlined";

import { useSegment } from "..";

const filters = [
  {
    section: "Organizações",
    value: "organization",
    icon: <DomainOutlinedIcon />,
    items: [
      {
        label: "Carteira",
        target: "profile",
      },
      {
        label: "Cidade",
        target: "city",
      },
      {
        label: "Estado",
        target: "state",
      },
      {
        label: "Referência",
        target: "reference",
      },
      {
        label: "Regime Tributário",
        target: "regime",
      },
      {
        label: "Área de Atuação",
        target: "area",
      },
    ],
  },
  {
    section: "Pessoas",
    value: "people",
    icon: <GroupIcon />,
    items: [
      {
        label: "Perfil",
        target: "person_profile",
      },
      {
        label: "Data de aniversário",
        target: "birthdate",
      },
    ],
  },
];

const SegmentAddFilter = () => {
  const segment = useSegment();
  const [state, setState] = useState(false);

  const handleAddItem = (item: any) => {
    const newFilter = [...segment.data.filters];

    newFilter.push(item);

    segment.set({ ...segment.data, filters: newFilter });
    setState(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => {
          setState(true);
        }}
      >
        Adicionar {segment.data.filters.length > 0 && " outro"} filtro
      </Button>
      <Drawer
        sx={{ zIndex: 1300 }}
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
          <Stack height="100%" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Selecionar um filtro
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
            <Box>
              {filters.map((filter: any) => (
                <Box key={`filter-${filter.value}`}>
                  <List>
                    <ListItem disablePadding>
                      <ListItemIcon>{filter.icon}</ListItemIcon>
                      <ListItemText primary={filter.section} />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    {filter.items.map((item: any) => (
                      <ListItem key={`item-${item.target}`} disablePadding>
                        <ListItemButton
                          sx={{ padding: "5px 10px" }}
                          onClick={() => {
                            handleAddItem(item);
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              color: "primary",
                              fontWeight: "medium",
                              variant: "body2",
                            }}
                            primary={item.label}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default SegmentAddFilter;
