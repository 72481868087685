import { AppBar, Box, Toolbar, Typography, Stack, Chip } from "@mui/material";

interface Props {
  title: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  button?: React.ReactNode;
  label?: string;
}

const ViewContent = ({ title, children, icon, label, button = "" }: Props) => {
  const tollbarHeight = 72;
  return (
    <Stack
      direction="column"
      justifyContent="start"
      alignItems="stretch"
      spacing={3}
      sx={{ minHeight: `calc(100% - ${tollbarHeight}px)` }}
    >
      <Box>
        <AppBar position="static" sx={{ borderRadius: 1 }}>
          <Toolbar variant="dense">
            {icon}
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              sx={{ flexGrow: !label ? 1 : 0 }}
            >
              {title}
            </Typography>
            {label && (
              <Box sx={{ flexGrow: label ? 1 : 0 }}>
                <Chip
                  label={label}
                  color="default"
                  size="small"
                  sx={{
                    textTransform: "uppercase",
                    ml: 1,
                    color: "#FFF !important",
                  }}
                />
              </Box>
            )}
            {button || ""}
          </Toolbar>
        </AppBar>
      </Box>
      {children}
    </Stack>
  );
};

export default ViewContent;
