import { useEffect, useState } from "react";
import {
  Alert,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import EditBox from "../../../../components/EditBox";

import { useMessage } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const MessageSubjectBox = () => {
  const snackbar = useSnackbar();
  const { data, save } = useMessage();
  const [subject, setSubject] = useState("");

  const handleSaveSubject = async () => {
    const payload = { subject };

    if (await save(payload)) {
      snackbar?.open("Assunto atualizado com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  useEffect(() => {
    setSubject(data.subject);
  }, [data.subject]);

  return (
    <EditBox
      state={!subject ? false : true}
      title="Assunto"
      icon={<CheckCircleIcon color={!subject ? "disabled" : "success"} />}
      buttonText={`${subject ? "Editar" : "Adicionar"} assunto`}
      text={
        <Typography
          variant={subject ? "body1" : "body2"}
          color={subject ? "text.primary" : "text.secondary"}
        >
          {subject ? subject : "Qual é a linha de assunto deste e-mail?"}
        </Typography>
      }
      helperText={
        <Typography variant="body2" color={"text.secondary"}>
          Qual é a linha de assunto deste e-mail?
        </Typography>
      }
      onSave={handleSaveSubject}
      saveDisabled={!subject}
    >
      <TextField
        label="Assunto"
        value={subject}
        fullWidth
        onChange={(e) => {
          setSubject(e.target.value);
        }}
      />
      {!subject && (
        <Alert severity="error" sx={{ mt: 1 }}>
          Defina o assunto deste e-mail.
        </Alert>
      )}
    </EditBox>
  );
};

export default MessageSubjectBox;
