import { Box, Typography } from "@mui/material";

const NpsProgressBox = (props: any) => {
  const { score, color, radius } = props;

  return (
    <Box
      sx={{
        p: "3px 0",
        width: `${score}%`,
        backgroundColor: color,
        textAlign: "center",
        color: "white",
        borderRadius: radius || "none",
      }}
    >
      <Typography variant="caption" fontWeight="bold">
        {score}%
      </Typography>
    </Box>
  );
};

export default NpsProgressBox;
