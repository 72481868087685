import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { fetchData } from "../../../../fetchData";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";
import SelectChipField from "../../../../components/SelectChipField";

import { profiles } from "../../../../utils/profileHandler";
import { profileSwitch } from "../../../../utils/profileHandler";

type FormValues = {
  name: string;
  login: string;
  organization: string;
  email: string;
  profile: Array<string>;
};

const AddPerson = () => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      profile: [],
    },
  });

  const showError = () => {
    snackbar?.open("Ops! No momento não foi possível criar a pessoa.", "error");
  };

  const handleLoginValidation = async (login: string) => {
    try {
      const check = await fetchData(`/users?login=${login}`, {
        method: "GET",
      });

      if (check.count) {
        snackbar?.open(
          "O nome de usuário escolhido já existe no sistema.",
          "warning",
        );
        return true;
      }
    } catch (error) {
      showError();
      return true;
    }
    return false;
  };

  const handleOrgValidation = (org: string) => {
    if (org.includes(";") || org.includes(".") || org.includes(" ")) {
      setError(
        "organization",
        {
          type: "focus",
          message: "Organização inválida. Verifique.",
        },
        { shouldFocus: true },
      );
      setFocus("organization");
      return false;
    }

    return true;
  };

  async function onSubmit(data: any) {
    if (!handleOrgValidation(data.organization)) return;
    data.client = data.organization
      .split(",")
      .map((string: string) => parseInt(string));

    data.email = [
      {
        value: data.email,
        label: "work",
        primary: true,
      },
    ];

    data.profile = data.profile.map((profile: string) =>
      profileSwitch(profile, true),
    );

    setProgress(true);

    const validateLogin = await handleLoginValidation(data.login);

    if (validateLogin) {
      setProgress(false);
      return;
    }

    try {
      const person = await fetchData(`/users`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      snackbar?.open("Pessoa criada com sucesso!", "success");

      navigate(`/persons/${person._id}`);
      modal?.close();
    } catch (error) {
      showError();
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("name", {
          required: "Informe o nome do contato.",
        })}
        label="Nome"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.name ? true : false}
      />
      {errors?.name && (
        <Alert severity="error">{errors.name.message?.toString()}</Alert>
      )}

      <TextField
        {...register("login", {
          required: "Informe o login de acesso do contato.",
        })}
        label="Usuário de acesso"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.login ? true : false}
      />
      {errors?.login && (
        <Alert severity="error">{errors.login.message?.toString()}</Alert>
      )}

      <TextField
        {...register("email", {
          required: "Informe o e-mail do contato.",
        })}
        label="E-mail"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.email ? true : false}
      />
      {errors?.email && (
        <Alert severity="error">{errors.email.message?.toString()}</Alert>
      )}

      <SelectChipField
        name="profile"
        register={register}
        get={getValues}
        required="Informe o perfil de acesso do contato."
        label="Perfil de acesso"
        error={errors?.profile ? true : false}
      >
        {profiles.map((profile) => (
          <MenuItem key={profile.value} value={profile.value}>
            {profile.value}
          </MenuItem>
        ))}
      </SelectChipField>
      {errors?.profile && (
        <Alert severity="error">{errors.profile.message?.toString()}</Alert>
      )}

      <TextField
        {...register("organization", {
          required: "Informe ao menos uma organização para acesso.",
        })}
        label="Cód. Organização"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        helperText="Separe por vírgulas para vincular mais de um."
        error={errors?.organization ? true : false}
      />
      {errors?.organization && (
        <Alert severity="error">
          {errors.organization.message?.toString()}
        </Alert>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={modal?.close}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Criar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default AddPerson;
