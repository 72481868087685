import { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Paper,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../../assets/images/logoHorizontal.svg";
import Symbol from "../../../../assets/images/symbol.svg";
import Template from "../../../../assets/images/messagingTemplateBg.svg";
import { useMessage } from "..";

const MessagePreviewDrawer = ({
  state,
  setState,
  width,
  expanded,
  handleChange,
}: {
  width: number | string;
  expanded: string | boolean;
  handleChange: any;
  state: any;
  setState: any;
}) => {
  const { data } = useMessage();
  const [alignment, setAlignment] = useState(expanded);

  const handleAlignmentChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
    handleChange(newAlignment);
  };

  useEffect(() => {
    setAlignment(expanded);
  }, [expanded]);

  useEffect(() => {
    if (data.method[0]) setAlignment(data.method[0]);
  }, [data.method]);

  return (
    <>
      <Button
        size="small"
        disabled={state}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
      >
        Ver como está
      </Button>
      <Drawer variant="persistent" anchor="right" open={state}>
        <Box width={width} sx={{ p: 3, pt: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body2"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Pré-visualização
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setState(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          {data.method.includes("email") && data.method.includes("sms") && (
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleAlignmentChange}
              aria-label="Platform"
              fullWidth
              size="small"
            >
              <ToggleButton value="email">E-mail</ToggleButton>
              <ToggleButton value="sms">SMS</ToggleButton>
            </ToggleButtonGroup>
          )}
          {alignment === "email" && (
            <Box sx={{ border: "1px solid #DDDDDD" }}>
              <Box sx={{ backgroundColor: "#e8f2fc", p: 3 }}>
                <img src={Logo} width={120} />
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ mt: 1 }}
                  color="initial"
                >
                  {data.header_text || "Chamada principal"}
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: "#FFF", p: 3 }}>
                <Typography variant="caption" component="div" color="initial">
                  {data.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.content
                          .toString()
                          .replace("<p><br></p>", ""),
                      }}
                    />
                  ) : (
                    "Conteúdo/Mensagem do E-mail"
                  )}
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: "#409cff", p: 3 }}>
                <Typography
                  variant="caption"
                  component="div"
                  textAlign="center"
                  color="#FFF"
                >
                  <b>Para você contar com a gente!</b>
                  <br />
                  Não fique com dúvidas, envie um e-mail para
                  consultoria@atlantico.digital.
                </Typography>
              </Box>
            </Box>
          )}
          {alignment === "sms" && (
            <Stack direction="row" spacing={2} sx={{ py: 3 }}>
              <Box>
                <img src={Symbol} width={30} />
              </Box>
              <Paper sx={{ flex: 1, p: 2 }} elevation={2}>
                {data.sms_content
                  ? `Atlantico: ${data.sms_content}`
                  : "Conteúdo/Mensagem do SMS"}
              </Paper>
            </Stack>
          )}
          {!alignment && <img src={Template} />}
        </Box>
      </Drawer>
    </>
  );
};

export default MessagePreviewDrawer;
