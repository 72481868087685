import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Button,
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Link,
  LinearProgress,
  Chip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";

import { fetchData } from "../../../../fetchData";
import { formatDate } from "../../../../utils";
import { useAuth } from "../../../../contexts/AuthContext";
import { usePerson } from "..";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const PersonNpsSurveyDrawer = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const person = usePerson();
  const snackbar = useSnackbar();
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [answered, setAnswered] = useState<Array<string>>([]);

  const init = async () => {
    setLoading(true);

    try {
      const answered = await fetchData(
        `/users/${person.data._id}/surveys/nps/scores`,
        {
          method: "GET",
        },
      );

      setAnswered(answered.map((row: any) => row.survey));

      const result = await fetchData(`/surveys/nps?limit=5&order=desc`, {
        method: "GET",
      });

      if (result) {
        setSurveys(
          result.rows.map((row: any) => ({
            id: row._id,
            title: row.title,
            option: row.option,
            status: row.status,
            created_at: formatDate(row.created_at, "D/M/Y H:i"),
            published_up: formatDate(row.published_up, "D/M/Y H:i"),
            published_down: formatDate(row.published_down, "D/M/Y H:i"),
            sended_at: formatDate(row.sended_at, "D/M/Y H:i"),
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleLinkShare = (id: string) => {
    navigator.clipboard.writeText(
      `https://surveys.atlantico.digital/nps/${id}?userid=${person.data._id}`,
    );
    snackbar?.open("Link copiado com sucesso!", "success");
  };

  useEffect(() => {
    if (!state) {
      setSurveys([]);
    }
  }, [state]);

  return (
    <>
      <Button
        size="small"
        color="tertiary"
        variant="outlined"
        onClick={() => {
          setState(true);
          init();
        }}
      >
        <LinkIcon sx={{ mr: 1 }} /> Link Pesquisa NPS
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <DonutSmallIcon />
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Pesquisas NPS
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            {loading && <LinearProgress />}
            {surveys.map((survey: any) => (
              <Card key={survey.id} variant="outlined">
                <CardContent>
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {survey.status === "editing" && "Criado em "}
                    {survey.status === "done" && "Concluído em "}
                    {survey.status === "sended" && "Enviado em "}
                    {survey.status === "editing" && survey.created_at}
                    {survey.status === "done" && survey.published_down}
                    {survey.status === "sended" && survey.sended_at}
                  </Typography>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();

                      navigate(
                        `/surveys/nps/${
                          survey.status === "editing" &&
                          auth?.user.permission_level === "manager"
                            ? "edit/"
                            : ""
                        }${survey.id}`,
                      );
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="body2" component="div">
                      {survey.title}
                    </Typography>
                  </Link>
                </CardContent>
                {survey.status !== "editing" && (
                  <CardActions>
                    {answered.includes(survey.id) && (
                      <Chip
                        color="success"
                        label="Pesquisa respondida"
                        size="small"
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: 500,
                          fontSize: 12,
                        }}
                      />
                    )}
                    {!answered.includes(survey.id) && (
                      <Button
                        size="small"
                        startIcon={<LinkIcon />}
                        variant="outlined"
                        onClick={() => {
                          handleLinkShare(survey.id);
                        }}
                      >
                        Copiar Link
                      </Button>
                    )}
                  </CardActions>
                )}
              </Card>
            ))}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default PersonNpsSurveyDrawer;
