import { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

import ViewContent from "../../../components/ViewContent";
import DataGrid from "../../../components/DataGrid";
import { stringAvatar } from "../../../utils/stringAvatar";
import { formatDate } from "../../../utils";
import { CsatIcon } from "../../../utils/labels";

import {
  Link,
  Backdrop,
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Avatar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";

import { fetchData } from "../../../fetchData";
import { navigateToOrganization } from "../../../utils/navigateToOrganization";

import DatePickerField from "../../../components/DatePickerField";

const defaultChartData = [
  {
    name: "Muito insatisfeito",
    total: 0,
    fill: "#C00514",
  },
  {
    name: "Insatisfeito",
    total: 0,
    fill: "#F54245",
  },
  {
    name: "Neutro",
    total: 0,
    fill: "#FEC230",
  },
  {
    name: "Satisfeito",
    total: 0,
    fill: "#92D14F",
  },
  {
    name: "Muito satisfeito",
    total: 0,
    fill: "#6EAA2E",
  },
];

const rows = [
  {
    id: 1,
    type: "task",
    name: "Eva Vilma da Silva",
    reference: 1274,
    task: 108231,
    ticket: 0,
    registered_at: new Date(),
    comment: "Tudo ok",
    answer: "very_satisfied",
  },
];

const Csat = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [type, setType] = useState("task");
  const [filter, setFilter] = useState("currentMonth");
  const [report, setReport] = useState<any>([]);
  const [chartData, setChartData] = useState(defaultChartData);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start: "",
      end: "",
    },
  });

  const handleTypeChange = (e: any, value: string) => {
    if (value !== null) {
      setType(value);
    }
  };

  const handleFilterChange = (e: any, value: string) => {
    if (value !== null) {
      setFilter(value);
    }
  };

  async function onCustomSubmit(data: any) {
    if (data.start && data.end) console.log(data);
    getReport({
      start: data.start,
      end: data.end,
    });
  }

  const getReport = async (custom?: { start: string; end: string }) => {
    const payload: {
      type: string;
      filter: string;
      custom?: object;
    } = {
      type,
      filter,
    };

    if (filter === "custom") payload.custom = custom;

    setLoading(true);

    try {
      const response = await fetchData(`/surveys/csat/report`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (response) {
        setReport(response);

        setChartData([
          {
            name: "Muito insatisfeito",
            total: response.result.very_dissatisfied.total,
            fill: "#C00514",
          },
          {
            name: "Insatisfeito",
            total: response.result.dissatisfied.total,
            fill: "#F54245",
          },
          {
            name: "Neutro",
            total: response.result.neutral.total,
            fill: "#FEC230",
          },
          {
            name: "Satisfeito",
            total: response.result.satisfied.total,
            fill: "#92D14F",
          },
          {
            name: "Muito satisfeito",
            total: response.result.very_satisfied.total,
            fill: "#6EAA2E",
          },
        ]);
      } else {
        setError(true);
        console.log(error);
      }

      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (filter != "custom") getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, filter]);

  return (
    <ViewContent
      title="Pesquisa de satisfação"
      icon={<InsertEmoticonIcon sx={{ mr: 2 }} />}
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Ops! Ocorreu um erro ao carregar a pesquisa NPS. Tente novamente mais
          tarde.
        </Alert>
      )}

      {!error && (
        <div>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Card>
                <CardContent>
                  <ToggleButtonGroup
                    color="primary"
                    value={type}
                    exclusive
                    onChange={handleTypeChange}
                    fullWidth
                    size="small"
                  >
                    <ToggleButton value="task">Tarefa</ToggleButton>
                    <ToggleButton value="ticket">Ticket</ToggleButton>
                  </ToggleButtonGroup>
                  <Divider sx={{ my: 2 }} />
                  <ToggleButtonGroup
                    orientation="vertical"
                    color="primary"
                    value={filter}
                    exclusive
                    onChange={handleFilterChange}
                    fullWidth
                    size="small"
                  >
                    <ToggleButton value="today" aria-label="today">
                      Hoje
                    </ToggleButton>
                    <ToggleButton value="last7days" aria-label="last7days">
                      Últimos 7 dias
                    </ToggleButton>
                    <ToggleButton
                      value="currentMonth"
                      aria-label="currentMonth"
                    >
                      Este mês
                    </ToggleButton>
                    <ToggleButton value="custom" aria-label="custom">
                      Personalizado
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Box sx={{ opacity: filter === "custom" ? 1 : ".3" }}>
                    <form onSubmit={handleSubmit(onCustomSubmit)}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="flex-start"
                        sx={{ mt: 2 }}
                      >
                        <Box flex={1}>
                          <DatePickerField
                            name="start"
                            register={register}
                            get={getValues}
                            set={setValue}
                            label="De"
                            disabled={filter === "custom" ? false : true}
                          />
                        </Box>
                        <Box flex={1}>
                          <DatePickerField
                            name="end"
                            register={register}
                            get={getValues}
                            set={setValue}
                            label="Até"
                            disabled={filter === "custom" ? false : true}
                          />
                        </Box>
                      </Stack>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={filter === "custom" ? false : true}
                        sx={{ mt: 1 }}
                      >
                        Consultar
                      </Button>
                    </form>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box width="100%" height={390}>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <ResponsiveContainer>
                  <BarChart width={500} height={400} data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="total" fill="#8884d8">
                      {chartData.map((row, index) => (
                        <Cell key={`cell-${index}`} fill={row.fill} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </Grid>

          {report.comments && report.comments.length > 0 && (
            <>
              <Divider sx={{ my: 3 }}>
                <Chip label="COMENTÁRIOS" />
              </Divider>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Contato</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Comentário</TableCell>
                      <TableCell>Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.comments.map((row: any) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Avatar
                              src={CsatIcon(row.answer)}
                              sx={{ width: 32, height: 32 }}
                            />
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                // navigate(`/organizations/${params.id}`);
                              }}
                              sx={{
                                overflow: "hidden",
                                justifyContent: "left",
                                cursor: "pointer",
                              }}
                            >
                              <Typography sx={{ flex: 1, m: 0 }}>
                                {row.user?.name ? row.user?.name : row.id}
                              </Typography>
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {row.client?.reference ? row.client?.reference : "-"}
                        </TableCell>
                        <TableCell>{row.comments}</TableCell>
                        <TableCell>
                          {formatDate(row.answered_at, "D/M/Y")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      )}
    </ViewContent>
  );
};

export default Csat;
