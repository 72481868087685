import {
  Modal as MuiModal,
  Card,
  CardContent,
  CardHeader,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";

import { useModal } from "../../contexts/ModalContext";

import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const Modal = () => {
  const modal = useModal();

  return (
    <MuiModal open={modal?.state.open} onClose={modal?.close}>
      <Card sx={style}>
        <CardHeader
          sx={{ borderBottom: "1px solid #CCCC" }}
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">{modal?.state.title}</Typography>
              <IconButton aria-label="delete" onClick={modal?.close} edge="end">
                <CloseIcon />
              </IconButton>
            </Stack>
          }
        />
        <CardContent>{modal?.state.children}</CardContent>
      </Card>
    </MuiModal>
  );
};

export default Modal;
