import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { profileSwitch } from "../../../utils/profileHandler";

import Profile from "./profile";
import Tabs from "./tabs";
import History from "./history";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, LinearProgress, Grid } from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";

import { fetchData } from "../../../fetchData";

export const PersonContext = createContext<{
  data?: any;
  set?: any;
  reload?: any;
}>({
  data: {},
});

export const usePerson = () => {
  return useContext(PersonContext);
};

const Person = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [person, setPerson] = useState<any>([]);
  const [reload, setReload] = useState<any>(null);

  const init = async () => {
    try {
      const person = await fetchData(`/users/${params.personID}`, {
        method: "GET",
      });

      person.profile = person.profile
        ? person.profile.map((item: string) => profileSwitch(item))
        : [];

      setPerson(person);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.personID, person._id]);

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <PersonContext.Provider
      value={{
        data: person,
        set: setPerson,
        reload: { state: reload, set: setReload },
      }}
    >
      <ViewContent title={person.name} icon={<PersonIcon sx={{ mr: 2 }} />}>
        <div>
          {loading && <LinearProgress sx={{ mt: 1 }} />}
          {!loading && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Profile />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Tabs />
                <History />
              </Grid>
            </Grid>
          )}
        </div>
      </ViewContent>
    </PersonContext.Provider>
  );
};

export default Person;
