import { Outlet } from "react-router-dom";
import { Container, Box, Alert } from "@mui/material";

import logo from "../../assets/images/symbol.svg";
import Bg from "../../assets/images/signinPattern.webp";
import Snackbar from "../../components/Snackbar";

function Auth() {
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.main",
        backgroundImage: `url(${Bg})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "center center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: { xs: 400, lg: 500 },
          margin: "0 auto",
        }}
      >
        <Box
          boxShadow={3}
          sx={{
            backgroundColor: "background.default",
            borderRadius: "1em",
            padding: { xs: "2em", lg: "4em 5em" },
            textAlign: "center",
          }}
        >
          <img
            src={logo}
            width="60"
            style={{ marginBottom: "40px" }}
            alt="Atlântico Digital"
          ></img>
          <Outlet />
        </Box>
      </Container>
      <Snackbar />
    </Container>
  );
}

export default Auth;
