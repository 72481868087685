import React, { createContext, useContext } from "react";

interface State {
  state?: any;
  open: (title: string, children: React.ReactNode) => void;
  close: () => void;
}

export const DialogContext = createContext<State | null>(null);

export const useDialog = () => {
  return useContext(DialogContext);
};
