import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import DonutSmallIcon from "@mui/icons-material/DonutSmall";

import ViewContent from "../../../components/ViewContent";
import { formatDate } from "../../../utils";
import { NpsIcon } from "../../../utils/labels";
import { stringAvatar } from "../../../utils/stringAvatar";

import very_dissatisfied from "../../../assets/images/angry.svg";
import neutral from "../../../assets/images/neutral.svg";
import very_satisfied from "../../../assets/images/bigsmile.svg";

import {
  Alert,
  Link,
  Backdrop,
  Chip,
  Stack,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Avatar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";

import { fetchData } from "../../../fetchData";

import DatePickerField from "../../../components/DatePickerField";
import { navigateToOrganization } from "../../../utils/navigateToOrganization";
import NpsScoreDrawer from "./drawer";
import NpsProgressBox from "../../../components/NpsProgressBox";
import { handleScoreColor } from "../../../utils";

import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useModal } from "../../../contexts/ModalContext";

const NpsIconBox = ({
  type,
  number,
  total,
}: {
  type?: string;
  number: number;
  total?: number;
}) => {
  let color = "#fd6643";
  let icon = very_dissatisfied;

  if (type === "passive") {
    color = "#FEC230";
    icon = neutral;
  }

  if (type === "promoter") {
    color = "#6EAA2E";
    icon = very_satisfied;
  }

  return (
    <Stack alignItems="center">
      {total && (
        <Typography variant="body1" fontWeight="bold">
          {total}
        </Typography>
      )}
      <Box
        sx={{
          border: `1px solid ${color}`,
          borderRadius: 2,
          p: 1,
          textAlign: "center",
        }}
      >
        <img src={icon} width={32} />
        <Typography
          variant="caption"
          fontWeight="bold"
          display="block"
          color={color}
        >
          {number}
        </Typography>
      </Box>
    </Stack>
  );
};

const NpsScore = () => {
  const snackbar = useSnackbar();
  const modal = useModal();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [type, setType] = useState("task");
  const [index, setIndex] = useState<number>(-1);
  const [filter, setFilter] = useState("alltime");
  const [report, setReport] = useState<any>([]);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start: "",
      end: "",
    },
  });

  const handleTypeChange = (e: any, value: string) => {
    if (value !== null) {
      setType(value);
    }
  };

  const handleFilterChange = (e: any, value: string) => {
    if (value !== null) {
      setFilter(value);
    }
  };

  async function onCustomSubmit(data: any) {
    getReport({
      start: data.start,
      end: data.end,
    });
  }

  const getReport = async (custom?: { start: string; end: string }) => {
    const payload: {
      npsId: string | undefined;
      filter: string;
      custom?: object;
    } = {
      npsId: params.npsID,
      filter,
    };

    if (filter === "custom") payload.custom = custom;

    setLoading(true);

    try {
      const response = await fetchData(`/surveys/nps/report`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (response) {
        setReport(response);
      } else {
        setError(true);
        console.log(error);
      }

      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  const handleSendNps = async () => {
    setSending(true);

    try {
      await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "PATCH",
      });

      modal?.close();
      snackbar?.open("Pesquisa reenviada com sucesso!", "success");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível reenviar a pesquisa NPS.",
        "error",
      );
    }

    setSending(false);
  };

  useEffect(() => {
    if (filter != "custom") getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, filter]);

  const SendConfirmationModal = () => {
    return (
      <Box>
        <Typography variant="body2" textAlign="center">
          A pesquisa será reenviada apenas para os contatos do segmento que não
          responderam a pesquisa até o momento.
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              modal?.close();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ flex: 1 }}
            onClick={() => {
              handleSendNps();
            }}
          >
            SIM
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <ViewContent
      title={`Pesquisa NPS: ${report.survey?.title || "..."}`}
      icon={<DonutSmallIcon sx={{ mr: 2 }} />}
      button={
        !loading && (
          <>
            <Button
              variant="contained"
              size="small"
              color="quaternary"
              onClick={() => {
                modal?.open(
                  "Reenviar Pesquisa NPS?",
                  <SendConfirmationModal />,
                );
              }}
            >
              {!sending && "Reenviar"}
              {sending && <CircularProgress size={18} color="white" />}
            </Button>
          </>
        )
      }
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Ops! Ocorreu um erro ao carregar a pesquisa NPS. Tente novamente mais
          tarde.
        </Alert>
      )}

      {!error && (
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Card>
                <CardContent>
                  <TextField
                    fullWidth
                    label="Filtro"
                    select
                    value={index}
                    variant="standard"
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setIndex(Number(e.target.value));
                    }}
                  >
                    <MenuItem key="geral" value={-1}>
                      Geral
                    </MenuItem>
                    {report.survey?.questions.map(
                      (question: string, index: number) => (
                        <MenuItem key={index} value={index}>
                          {question}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                  <ToggleButtonGroup
                    orientation="vertical"
                    color="primary"
                    value={filter}
                    exclusive
                    onChange={handleFilterChange}
                    fullWidth
                    size="small"
                  >
                    <ToggleButton value="alltime" aria-label="alltime">
                      Todo período veiculado
                    </ToggleButton>
                    <ToggleButton value="last7days" aria-label="last7days">
                      Últimos 7 dias
                    </ToggleButton>
                    <ToggleButton
                      value="currentMonth"
                      aria-label="currentMonth"
                    >
                      Este mês
                    </ToggleButton>
                    <ToggleButton value="custom" aria-label="custom">
                      Personalizado
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Box sx={{ opacity: filter === "custom" ? 1 : ".3" }}>
                    <form onSubmit={handleSubmit(onCustomSubmit)}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="flex-start"
                        sx={{ mt: 2 }}
                      >
                        <Box flex={1}>
                          <DatePickerField
                            name="start"
                            register={register}
                            get={getValues}
                            set={setValue}
                            label="De"
                            disabled={filter === "custom" ? false : true}
                          />
                        </Box>
                        <Box flex={1}>
                          <DatePickerField
                            name="end"
                            register={register}
                            get={getValues}
                            set={setValue}
                            label="Até"
                            disabled={filter === "custom" ? false : true}
                          />
                        </Box>
                      </Stack>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={filter === "custom" ? false : true}
                        sx={{ mt: 1 }}
                      >
                        Consultar
                      </Button>
                    </form>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Stack
                width="100%"
                height={372}
                direction="column"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: 200,
                    height: 200,
                    borderRadius: 200,
                    borderWidth: 7,
                    borderStyle: "solid",
                    borderColor: handleScoreColor(report.graph?.score || 0),
                  }}
                >
                  <Typography
                    variant="h2"
                    fontWeight={500}
                    color={handleScoreColor(report.graph?.score || 0)}
                  >
                    {report.graph?.score || 0}
                  </Typography>
                  <Typography variant="caption">NPS SCORE</Typography>
                </Stack>
                <Stack direction="column" spacing={2} width="100%">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <NpsScoreDrawer
                      label={`Detratores = ${
                        report.graph?.detractors.total || "0"
                      }`}
                      icon={<img src={very_dissatisfied} width={18} />}
                      color="#fd6643"
                      data={report.graph?.detractors.rows.map((row: any) => ({
                        ...row,
                        id: row._id,
                      }))}
                    />
                    <NpsScoreDrawer
                      label={`Passivos = ${
                        report.graph?.passives.total || "0"
                      }`}
                      icon={<img src={neutral} width={18} />}
                      color="#FEC230"
                      data={report.graph?.passives.rows.map((row: any) => ({
                        ...row,
                        id: row._id,
                      }))}
                    />
                    <NpsScoreDrawer
                      label={`Promotores = ${
                        report.graph?.promoters.total || "0"
                      }`}
                      icon={<img src={very_satisfied} width={18} />}
                      color="#6EAA2E"
                      data={report.graph?.promoters.rows.map((row: any) => ({
                        ...row,
                        id: row._id,
                      }))}
                    />
                  </Stack>
                  <Stack direction="row" width="100%">
                    <NpsProgressBox
                      score={report.graph?.detractors.percentual || 0}
                      color="#fd6643"
                      radius="5px 0 0 5px"
                    />
                    <NpsProgressBox
                      score={report.graph?.passives.percentual || 0}
                      color="#FEC230"
                    />
                    <NpsProgressBox
                      score={report.graph?.promoters.percentual || 0}
                      color="#6EAA2E"
                      radius="0 5px 5px 0"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Stack
            width="100%"
            direction={{ xs: "column", lg: "row" }}
            sx={{ mt: 4 }}
            spacing={2}
          >
            <Box flex={2}>
              <Divider>
                <Chip label="DETRATORES" sx={{ mb: 2 }} />
              </Divider>
              <Stack
                width="100%"
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <NpsIconBox
                  number={0}
                  total={report?.result?.[0]?.total ?? 0}
                />
                <NpsIconBox
                  number={1}
                  total={report?.result?.[1]?.total ?? 0}
                />
                <NpsIconBox
                  number={2}
                  total={report?.result?.[2]?.total ?? 0}
                />
                <NpsIconBox
                  number={3}
                  total={report?.result?.[3]?.total ?? 0}
                />
                <NpsIconBox
                  number={4}
                  total={report?.result?.[4]?.total ?? 0}
                />
                <NpsIconBox
                  number={5}
                  total={report?.result?.[5]?.total ?? 0}
                />
                <NpsIconBox
                  number={6}
                  total={report?.result?.[6]?.total ?? 0}
                />
              </Stack>
            </Box>
            <Box flex={1}>
              <Divider>
                <Chip label="PASSIVOS" sx={{ mb: 2 }} />
              </Divider>
              <Stack
                width="100%"
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <NpsIconBox
                  number={7}
                  total={report?.result?.[7]?.total ?? 0}
                  type="passive"
                />
                <NpsIconBox
                  number={8}
                  total={report?.result?.[8]?.total ?? 0}
                  type="passive"
                />
              </Stack>
            </Box>
            <Box flex={1}>
              <Divider>
                <Chip label="PROMOTORES" sx={{ mb: 2 }} />
              </Divider>
              <Stack
                width="100%"
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <NpsIconBox
                  number={9}
                  total={report?.result?.[9]?.total ?? 0}
                  type="promoter"
                />
                <NpsIconBox
                  number={10}
                  total={report?.result?.[10]?.total ?? 0}
                  type="promoter"
                />
              </Stack>
            </Box>
          </Stack>

          {report.comments && report.comments.length > 0 && (
            <>
              <Divider sx={{ my: 3, mt: 4 }}>
                <Chip label="COMENTÁRIOS" />
              </Divider>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Contato</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Comentário</TableCell>
                      <TableCell>Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.comments.map((row: any) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Avatar {...stringAvatar(row.user.name)} />
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/persons/${row.user._id}`);
                              }}
                              sx={{
                                overflow: "hidden",
                                justifyContent: "left",
                                cursor: "pointer",
                              }}
                            >
                              <Typography sx={{ flex: 1, m: 0 }}>
                                {row.user?.name ? row.user?.name : row.id}
                              </Typography>
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {row.client?.reference && (
                            <Chip
                              label={row.client?.reference}
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                navigateToOrganization(
                                  row.client?.reference,
                                  navigate,
                                );
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell width={500}>
                          {row.answers.map((answer: any, index: number) => (
                            <Box key={`comments-${index}`}>
                              {index > 0 && <Divider sx={{ my: 2 }} />}
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <NpsIconBox
                                  number={answer.value}
                                  type={
                                    answer.value > 8
                                      ? "promoter"
                                      : answer.value > 6
                                      ? "passive"
                                      : ""
                                  }
                                />
                                <Typography variant="body2">
                                  {answer.comment}
                                </Typography>
                              </Stack>
                            </Box>
                          ))}
                        </TableCell>
                        <TableCell>
                          {formatDate(row.answered_at, "D/M/Y")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      )}
    </ViewContent>
  );
};

export default NpsScore;
