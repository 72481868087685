import { BrowserRouter, Route, Routes } from "react-router-dom";

import SignIn from "../views/SignIn";
import Auth from "../layouts/Auth/Auth";
import ForgetPassword from "../views/ForgetPassword";

import Surveys from "../layouts/Surveys/Surveys";
import NpsSurvey from "../views/Surveys/nps";

export const PublicRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />}>
          <Route path="/" element={<SignIn />} />
          <Route path="/recover" element={<ForgetPassword />} />
          <Route path="*" element={<SignIn />} />
        </Route>
        <Route path="/nps" element={<Surveys />}>
          <Route path=":id" element={<NpsSurvey />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
