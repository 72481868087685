import { Alert, AlertTitle } from "@mui/material";

const Dashboard = () => {
  return (
    <Alert severity="info" icon={false}>
      <AlertTitle>Acesso do Cliente</AlertTitle>
      No momento o acesso do cliente não está disponível!
    </Alert>
  );
};

export default Dashboard;
